import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useCallback, useState } from "react";
import logo from "../images/think-logo.svg";

import { PasswordComponent, Form } from "@truenorthmortgage/olympus";

import {
  createBorrowerUserMutation,
  emailFromActivationTokenQuery,
  loginMutation,
} from "../queries";
import { useIntl } from "react-intl";
import { handleQueryError, notify } from "../utils";
import { LoginMutation, User } from "../models/user";
import { PhoneLink } from "../components";

export interface EmailFromActivationQuery {
  emailFromActivationToken: string;
}

export interface CreateBorrowerUserMutation {
  createBorrowerUser: User;
}

const CreateBorrowerAccount = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const intl = useIntl();
  const greeting = intl.formatMessage({ id: "Set Your Account Password" });
  const info = intl.formatMessage({
    id: "Please choose a strong password to activate your account.",
  });
  const buttonlabel = intl.formatMessage({ id: "Activate" });
  const successLabel = intl.formatMessage({ id: "Account Created" });
  const continueLabel = intl.formatMessage({ id: "Continue" });

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    success: false,
  });
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | null) => void>
  >({});

  const { data: emailData, loading: loadingEmail } =
    useQuery<EmailFromActivationQuery>(emailFromActivationTokenQuery, {
      variables: { token },
    });

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | Date | null) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [loginUserFunc, loginStatus] = useMutation<LoginMutation>(
    loginMutation,
    {
      onError(loginError) {
        handleQueryError(
          loginError,
          "Account login details not found, please try again"
        );
        window.location.replace("/login");
      },
      onCompleted(data) {
        sessionStorage.setItem("thinkToken", data.loginBorrower);
        window.location.replace("/");
      },
    }
  );

  const loginUser = useCallback(() => {
    try {
      const variables = {
        email: emailData!.emailFromActivationToken,
        password: state.password,
      };

      loginUserFunc({ variables });
    } catch (e) {
      handleQueryError(e, "Account login details not found, please try again");
      window.location.replace("/login");
    }
  }, [loginUserFunc, state, emailData]);

  const [createBorrowerAccount, status] =
    useMutation<CreateBorrowerUserMutation>(createBorrowerUserMutation, {
      onError(createError) {
        handleQueryError(
          createError,
          "Account login details not found, please try again. [" +
            createError.message +
            "]"
        );
      },
      onCompleted() {
        setState((state) => Object.assign({}, state, { success: true }));
      },
    });

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    if (state.password && state.password === state.confirmPassword) {
      await createBorrowerAccount({
        variables: { password: state.password, token },
      });
    } else {
      notify("Passwords must match!", "error");
    }

    setIsSubmitted(false);
  }, [createBorrowerAccount, state, token]);

  return loadingEmail ? (
    <div className="loading-full">
      <img src="/think-loader.svg" alt="loader" />
    </div>
  ) : (
    <div className="has-footer">
      <div className="setup-card">
        <div className="img-container">
          <a href="/">
            <img src={logo} className="app-logo" alt="logo" />
          </a>
        </div>
        <h1>{state.success ? successLabel : greeting}</h1>
        {!state.success ? <p>{info}</p> : null}
        {state.success ? (
          <div>
            <div>
              The account has been created successfully. Click below to log in
              automatically.
            </div>
            <div>
              <button className="button primary" onClick={loginUser}>
                {loginStatus.loading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  continueLabel
                )}
              </button>
            </div>
          </div>
        ) : (
          <Form>
            <input
              className="disabled"
              disabled={true}
              value={emailData?.emailFromActivationToken}
            />

            <PasswordComponent
              placeholder={intl.formatMessage({ id: "Password" })}
              value={state.password}
              onChange={onChange("password")}
            />

            <PasswordComponent
              placeholder={intl.formatMessage({ id: "Repeat Password" })}
              value={state.confirmPassword}
              onChange={onChange("confirmPassword")}
            />

            <button
              className="button primary"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              {status.loading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                buttonlabel
              )}
            </button>
          </Form>
        )}
      </div>
      <div className="footer">
        <div>
          Need help?{" "}
          <strong>
            <PhoneLink />
          </strong>
        </div>
      </div>
    </div>
  );
};

export default CreateBorrowerAccount;
