import { BasicRow, Table, TextCell, utils } from "@truenorthmortgage/olympus";
import { FC } from "react";
import { LoanQuery } from "../../../models/loan";

export interface StatementsTabProps {
  data?: {
    loanData: LoanQuery;
  };
}

const StatementsTab: FC<StatementsTabProps> = ({ data }) => {
  const monthlyStatementsLabel = "Monthly Statements";

  if (!data) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return (
    <>
      <h2>{monthlyStatementsLabel}</h2>
      <div className="divider" />
      <Table className="transactions">
        {data.loanData?.borrowerLoan.monthlyStatements.map((statement: any) => (
          <BasicRow key={"statement-" + statement.id}>
            <TextCell>
              <div data-dd-privacy="mask">
                {utils.date.getShortDate(statement.startDate)} to{" "}
                {utils.date.getShortDate(statement.endDate)}
              </div>
            </TextCell>
            <TextCell>
              <a href={statement.url} download>
                Download
              </a>
            </TextCell>
          </BasicRow>
        ))}
      </Table>
    </>
  );
};

export default StatementsTab;
