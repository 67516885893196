import {
  utils,
  Table,
  BasicRow,
  TextCell,
  CellHeader,
} from "@truenorthmortgage/olympus";
import { useIntl } from "react-intl";

const PastTransactionsTableComponent = ({
  label,
  headerLabelTransaction,
  headerLabelAmount,
  headerLabelBalance,
  data,
}: any) => {
  const intl = useIntl();

  const transactions =
    data.transactionData?.allBorrowerLoanTransactions.transactions;
  const pastTransactionsHTML: JSX.Element[] = [];

  // Compose past transactions table
  for (let i = 0; i < transactions.length; i++) {
    const transaction = transactions[i];

    const firstRowOfDate =
      // First row is always first row of date
      // or if the date of the previous row is not the same
      i === 0 ||
      utils.date.getShortDate(transactions[i - 1].effectiveDate) !==
        utils.date.getShortDate(transaction.effectiveDate)
        ? true
        : false;

    pastTransactionsHTML.push(
      <BasicRow key={"pasttransaction-" + transaction.id}>
        <TextCell>
          <div data-dd-privacy="mask">
            {utils.date.getShortDate(transaction.effectiveDate)}
            <span>{intl.formatMessage({ id: transaction.type })}</span>
          </div>
        </TextCell>
        <TextCell className="right">
          <div data-dd-privacy="mask">
            {transaction.amountInCents < 0 ? (
              <span className="negative-number">
                {utils.currency.centsToDollars(transaction.amountInCents)}
              </span>
            ) : (
              utils.currency.centsToDollars(transaction.amountInCents)
            )}
          </div>
        </TextCell>
        <TextCell className="right">
          <div data-dd-privacy="mask">
            {firstRowOfDate
              ? utils.currency.centsToDollars(transaction.balance)
              : null}
          </div>
        </TextCell>
      </BasicRow>
    );
  }

  return (
    <>
      <h2>{intl.formatMessage({ id: label })}</h2>
      <div className="divider" />
      <Table className="transactions x-scrollable">
        <CellHeader className="section-header">
          <TextCell>{headerLabelTransaction}</TextCell>
          <TextCell className="right">{headerLabelAmount}</TextCell>
          <TextCell className="right">{headerLabelBalance}</TextCell>
        </CellHeader>
        {transactions!.length > 0 ? (
          pastTransactionsHTML
        ) : (
          <BasicRow>
            <TextCell colSpan={2}>No Transactions Found</TextCell>
          </BasicRow>
        )}
      </Table>
    </>
  );
};

export default PastTransactionsTableComponent;
