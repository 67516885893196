import { Link } from "react-router-dom";
import logo from "../images/think-logo.svg";

const NavComponent = () => {
  const closeNav = () => {
    const items = document.getElementById("nav-items");
    if (items) items.className = "";
  };

  const toggleNav = () => {
    const items = document.getElementById("nav-items");
    if (items) items.className = items.className === "open" ? "" : "open";
  };

  const logout = () => {
    sessionStorage.removeItem("thinkToken");
    window.location.replace("/login");
  };

  const displayVersion = "THINK Borrower Portal " + window.APP_VERSION;

  return (
    <nav id="nav-bar">
      <div className="nav-bar">
        <div className="nav-bar-contents">
          <div></div>
          <a href="/" title={displayVersion}>
            <img src={logo} className="app-logo" alt="logo" />
          </a>
          <div onClick={toggleNav}>
            <i className="fa fa-bars primary"></i>
          </div>
        </div>
      </div>
      <div id="nav-items" onClick={closeNav}>
        <div onClick={closeNav} className="close-button">
          <i className="fa fa-close"></i>
        </div>
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/account">My Account</Link>
          </li>
          <li>
            <button className="link-button" onClick={() => logout()}>
              Logout
            </button>
          </li>
        </ul>
      </div>
      <div className="nav-mask" onClick={closeNav} />
    </nav>
  );
};

export default NavComponent;
