import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import MakePaymentToHeloc from "../modal/MakePaymentToHeloc";
import WithdrawFromHeloc from "../modal/WithdrawFromHeloc";
import RecurringPayment from "../modal/RecurringPayment";
import {
  loanQuery,
  transactionsQuery,
  pendingChangeRequestsQuery,
} from "../queries";
import { useQueryWithRefresh } from "../utils";

import {
  ActionCards,
  ActionCard,
  TabGroup,
  TabGroupTab,
  utils,
  TabGroupHandle,
} from "@truenorthmortgage/olympus";
import { handleQueryError } from "../utils";
import ModalComponent from "../components/ModalComponent";
import { useNavigate, useParams } from "react-router-dom";
import { LoanQuery } from "../models/loan";
import {
  AllTransactionsQuery,
  PendingPaymentRequestsQuery,
} from "../models/payment";
import DetailsTab from "./heloc/tabs/DetailsTab";
import ActivityTab from "./heloc/tabs/ActivityTab";
import StatementsTab from "./heloc/tabs/StatementsTab";
import DocumentsTab from "./DocumentsTab";

const Heloc = () => {
  const intl = useIntl();

  const fundsAvailableLabel = intl.formatMessage({ id: "Funds Available" });
  const nextPaymentLabel = intl.formatMessage({ id: "Next Payment Date" });
  const optionLabel = intl.formatMessage({ id: "HELOC Actions" });
  const withdrawLabel = intl.formatMessage({ id: "Withdraw Funds From HELOC" });
  const paymentLabel = intl.formatMessage({ id: "Make Payment To HELOC" });
  //const paymentDateLabel = intl.formatMessage({ id: "Change Date of Payments" });
  const setupRecurringPaymentsLabel = intl.formatMessage({
    id: "Set Up Recurring Payments",
  });
  //const payoutLabel = intl.formatMessage({ id: "Payout HELOC" });
  const navigate = useNavigate();

  const { loanNumber, tab } = useParams();
  const tabs = ["details", "activity", "statements", "documents"];
  const modalDefault: any = {
    withdrawal: false,
    payment: false,
    recurring: false,
  };

  const [modalOpen, setModalOpen] =
    useState<Record<string, boolean>>(modalDefault);
  const ref = useRef<TabGroupHandle>(null);

  const [
    getLoan,
    { data: loanData, loading: loadingLoan, called: loanCalled },
  ] = useQueryWithRefresh<LoanQuery>(loanQuery, {
    variables: { loanNumber: parseInt(loanNumber || "0") },
    onError(loanError: any) {
      handleQueryError(loanError, "Failed to load loan");
    },
  });

  useEffect(() => {
    getLoan();
  }, [getLoan]);

  const [getTransactions] = useQueryWithRefresh<AllTransactionsQuery>(
    transactionsQuery,
    {
      variables: { loanNumber: parseInt(loanNumber || "0") },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load transactions");
      },
    }
  );

  const [getRequestData] = useQueryWithRefresh<PendingPaymentRequestsQuery>(
    pendingChangeRequestsQuery,
    {
      variables: { loanNumber: parseInt(loanNumber || "0") },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load pending requests");
      },
    }
  );

  const setModal = (key: string, value: boolean) => {
    setModalOpen((state: Record<string, boolean>) =>
      Object.assign({}, state, { [key]: value })
    );
    ref.current?.refreshData();
  };

  const cards = useMemo(
    () =>
      loanData
        ? [
            {
              label: withdrawLabel,
              icon: "money-from-bracket",
              key: "withdrawal",
              modal: {
                header: intl.formatMessage({ id: "Withdraw from HELOC" }),
                elem: (
                  <WithdrawFromHeloc
                    data={{
                      heloc: loanData?.borrowerLoan,
                    }}
                    fundsAvailable={
                      loanData?.borrowerLoan.mortgageBalance.available
                    }
                    closeFunc={() => setModal("withdrawal", false)}
                  />
                ),
              },
            },
            {
              label: paymentLabel,
              icon: "circle-dollar-to-slot",
              key: "payment",
              modal: {
                header: intl.formatMessage({ id: "Make Payment To HELOC" }),
                elem: (
                  <MakePaymentToHeloc
                    data={{
                      sources: [
                        { value: "123456789123", label: "RBC - 123456789123" },
                      ],
                      heloc: loanData?.borrowerLoan,
                    }}
                    currentBalance={
                      loanData?.borrowerLoan.mortgageBalance.balance
                    }
                    closeFunc={() => setModal("payment", false)}
                  />
                ),
              },
            },
            // {
            //   label: paymentDateLabel,
            //   icon: "calendar-pen",
            //   path: "heloc",
            //   modal: {
            //     header: intl.formatMessage({ id: "Request Payment Date Change" }),
            //     elem: <RequestPaymentDateChange />,
            //     loadProps: async () => ({
            //       data: {
            //         currentPaymentDate: "The 15th of Each Month", //placeholder for actual value
            //       },
            //     }),
            //   },
            // },
            {
              label: setupRecurringPaymentsLabel,
              icon: "recycle",
              key: "recurring",
              modal: {
                header: intl.formatMessage({ id: "Set Up Recurring Payments" }),
                elem: (
                  <RecurringPayment
                    data={{
                      accounts: [
                        { value: "123456789123", label: "RBC - 123456789123" },
                      ],
                      heloc: loanData?.borrowerLoan,
                    }}
                    closeFunc={() => setModal("recurring", false)}
                  />
                ),
              },
            },
            // { label: payoutLabel, icon: "close", path: "heloc" },
          ]
        : null,
    [
      intl,
      withdrawLabel,
      paymentLabel,
      /*paymentDateLabel, recurringPaymentsLabel, payoutLabel,*/ loanData,
      setupRecurringPaymentsLabel,
    ]
  );

  return loadingLoan || !loanCalled ? (
    <div className="loading-full">
      <img src="/think-loader.svg" alt="" />
    </div>
  ) : (
    <div className="heloc">
      <div className="loan-header">
        <h3>{fundsAvailableLabel}</h3>
        <h1 data-dd-privacy="mask">
          {utils.currency.centsToDollars(
            loanData?.borrowerLoan.mortgageBalance.available
          )}
        </h1>
        <p>
          {nextPaymentLabel}&nbsp;
          <span className="primary" data-dd-privacy="mask">
            {utils.date.getShortDate(loanData!.borrowerLoan.nextPaymentDate!)}
          </span>
        </p>
      </div>

      <h2>{optionLabel}</h2>
      <div className="divider" />

      <ActionCards>
        {cards!.map(({ label, icon, key }) => (
          <ActionCard key={key} onClick={() => setModal(key, true)}>
            <i className={"fa fa-" + icon}></i>
            <span>{label}</span>
          </ActionCard>
        ))}
      </ActionCards>

      {cards!.map(({ modal, key }) => (
        <ModalComponent
          key={key}
          modalHeader={modal.header}
          isOpen={modalOpen[key]}
          closeFunc={() => setModal(key, false)}
          iconClass="fa fa-close"
        >
          {modal.elem}
        </ModalComponent>
      ))}

      <TabGroup
        ref={ref}
        selectedTab={tabs.indexOf(tab || "details")}
        onTabChange={(tab) =>
          navigate("/heloc/" + loanNumber + "/" + tabs[tab])
        }
      >
        <TabGroupTab
          name="Details"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <DetailsTab />
        </TabGroupTab>
        <TabGroupTab
          name="Activity"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
              requestData: await getRequestData(),
              transactionData: await getTransactions(),
              setModal,
              modalOpen,
            },
          })}
        >
          <ActivityTab />
        </TabGroupTab>
        <TabGroupTab
          name="Statements"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <StatementsTab />
        </TabGroupTab>
        <TabGroupTab
          name="Documents"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <DocumentsTab productType="heloc" />
        </TabGroupTab>
      </TabGroup>
      <div className="footer-content"></div>
    </div>
  );
};

export default Heloc;
