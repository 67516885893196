import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  GetAmortizationTableByAnniversaryYearForBorrowerLoan,
  GetAmortizationTableByAnniversaryYearForBorrowerLoanQuery,
  LoanQuery,
} from "../../models/loan";
import {
  BasicRow,
  RawCell,
  Table,
  TextCell,
  pushNotification,
  store,
  utils,
} from "@truenorthmortgage/olympus";
import {
  nMonthsToYearMonthString,
  paymentFrequencyToHumanReadable,
} from "../../utils";
import { getAmortizationTableByAnniversaryYearForBorrowerLoanQuery } from "../../queries";
import { useLazyQuery } from "@apollo/client";
import React from "react";

export interface AmortizingAmortizationTabProps {
  data?: {
    loanData: LoanQuery;
  };
}

const AmortizingAmortizationTab: FC<AmortizingAmortizationTabProps> = ({
  data,
}) => {
  const intl = useIntl();
  const [amortizationTable, setAmortizationTable] = useState<
    GetAmortizationTableByAnniversaryYearForBorrowerLoan[]
  >([]);

  // Intl translation labels
  const detailsHeaderLabel = intl.formatMessage({ id: "Mortgage Details" });
  const principalBalanceRowTitle = intl.formatMessage({
    id: "Principal Balance",
  });
  const interestRateRowTitle = intl.formatMessage({ id: "Interest Rate" });
  const termRowTitle = intl.formatMessage({ id: "Term" });
  const paymentFrequencyRowTitle = intl.formatMessage({
    id: "Payment Frequency",
  });

  // Query for Amortization Table
  const [
    getAmortizationTable,
    { data: amortizationTableData, loading: amortizationTableLoading },
  ] = useLazyQuery<GetAmortizationTableByAnniversaryYearForBorrowerLoanQuery>(
    getAmortizationTableByAnniversaryYearForBorrowerLoanQuery,
    {
      onError() {
        setAmortizationTable([]);
        store.dispatch(
          pushNotification({
            message: "Failed to load amortization table for this loan!",
            class: "error",
          })
        );
      },
    }
  );

  // Update Amortization Table displayed when data is loaded
  useEffect(() => {
    setAmortizationTable(
      amortizationTableData?.getAmortizationTableByAnniversaryYearForBorrowerLoan ||
        []
    );
  }, [amortizationTableData]);

  // Get Amortization Table on initial load
  useEffect(() => {
    // Clear Amortization Table while the data is loading
    setAmortizationTable([]);

    // Load Amortization Table
    if (data?.loanData?.borrowerLoan?.reference) {
      getAmortizationTable({
        variables: {
          reference: data?.loanData?.borrowerLoan?.reference.toString(),
        },
      });
    }
  }, [data?.loanData?.borrowerLoan?.reference]);

  // Set up final amortized values for later calculating
  const [finalRemainingPrincipal, setFinalRemainingPrincipal] =
    useState<number>(0);
  const [finalInterestAmortized, setFinalInterestAmortized] =
    useState<number>(0);
  const [finalPrincipalAmortized, setFinalPrincipalAmortized] =
    useState<number>(0);

  // Calculate End of Term amortized values
  useEffect(() => {
    if (amortizationTable) {
      setFinalRemainingPrincipal(
        parseInt(
          amortizationTable[amortizationTable?.length - 1]?.stats
            .principalRemainingAtYearEnd
        )
      );

      //   Sum up total interest and principal amortized over the table
      let totalInterest = 0;
      let totalPrincipal = 0;
      amortizationTable.forEach((year) => {
        totalInterest += parseInt(year.stats.totalInterestInAnniversaryYear);
        totalPrincipal += parseInt(year.stats.totalPrincipalInAnniversaryYear);
      });
      setFinalInterestAmortized(totalInterest);
      setFinalPrincipalAmortized(totalPrincipal);
    }
  }, [amortizationTable]);

  // Toggle Row Visibility for implementing accordion
  const [rowVisibility, setRowVisibility] = useState<boolean[]>([]);
  function toggleRow(index: number) {
    const toggleValue = !rowVisibility[index];

    setRowVisibility((currentValue) => {
      const newValue = [...currentValue];
      newValue[index] = toggleValue;
      return newValue;
    });
  }

  // Display loading spinner while data is loading
  if (!data || amortizationTableLoading) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return (
    <>
      <h2>{detailsHeaderLabel}</h2>
      <div className="divider" />
      <Table className="four-column-table x-scrollable">
        <BasicRow>
          <TextCell className="bold">{principalBalanceRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                data?.loanData?.borrowerLoan?.mortgageBalance?.principal
              )}
            </div>
          </TextCell>
          <TextCell className="bold">{termRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data?.loanData?.borrowerLoan.product.termLengthInMonths
                ? nMonthsToYearMonthString(
                    data.loanData.borrowerLoan.product.termLengthInMonths
                  )
                : null}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell className="bold">{interestRateRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data?.loanData?.borrowerLoan.loanRate.currentRate
                ? `${data.loanData?.borrowerLoan.loanRate.currentRate}%`
                : null}
            </div>
          </TextCell>
          <TextCell className="bold">{paymentFrequencyRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data?.loanData?.borrowerLoan.activePaymentSchedule.paymentType
                ? paymentFrequencyToHumanReadable(
                    data.loanData?.borrowerLoan.activePaymentSchedule
                      .paymentType
                  )
                : null}
            </div>
          </TextCell>
        </BasicRow>
      </Table>

      {/* Render the amortization table only if data is present. Otherwise, the table tag complains. */}
      {amortizationTable && amortizationTable.length > 0 ? (
        <>
          <Table className="accordion x-scrollable">
            <>
              {/* Display Table headers  */}
              <BasicRow rowClasses={["row-header"]}>
                <TextCell>Amortization</TextCell>
                <TextCell className="width-20-percent right">Interest</TextCell>
                <TextCell className="width-20-percent right">
                  Principal
                </TextCell>
                <TextCell className="width-20-percent right">Balance</TextCell>
              </BasicRow>
              {amortizationTable.map(
                (
                  amortizationRow: GetAmortizationTableByAnniversaryYearForBorrowerLoan,
                  index: number
                ) => {
                  const totalYears = amortizationTable.length;

                  return (
                    <React.Fragment key={index}>
                      {/* Need to Render as a <tr> instead of a BasicRow, since the latter does not propogate the onClick handler */}
                      <tr
                        key={`year-${index}`}
                        className="faux-row accordion-header"
                        onClick={() => toggleRow(index)}
                      >
                        <TextCell>
                          {/* Index starting from 1 */}
                          YR {index + 1}:{" "}
                          <span
                            className="unset-all-style"
                            data-dd-privacy="mask"
                          >
                            {utils.currency.centsToDollars(
                              amortizationRow.stats
                                .totalPaymentsInAnniversaryYear
                            )}
                          </span>
                        </TextCell>
                        <TextCell className="width-20-percent right">
                          <div data-dd-privacy="mask">
                            {utils.currency.centsToDollars(
                              amortizationRow.stats
                                .totalInterestInAnniversaryYear
                            )}
                          </div>
                        </TextCell>
                        <TextCell className="width-20-percent right">
                          <div data-dd-privacy="mask">
                            {utils.currency.centsToDollars(
                              amortizationRow.stats
                                .totalPrincipalInAnniversaryYear
                            )}
                          </div>
                        </TextCell>
                        <TextCell className="width-20-percent right">
                          <div data-dd-privacy="mask">
                            {utils.currency.centsToDollars(
                              amortizationRow.stats.principalRemainingAtYearEnd
                            )}
                          </div>
                        </TextCell>
                        <RawCell className="min-width-td">
                          {rowVisibility[index] ? (
                            <i className="fa fa-circle-minus" />
                          ) : (
                            <i className="fa fa-circle-plus" />
                          )}
                        </RawCell>
                      </tr>
                      {amortizationRow.values.map(
                        (payment, paymentIndex: number) => (
                          <BasicRow
                            key={`year-${index}-payment-${paymentIndex}`}
                            hideRow={!rowVisibility[index]}
                          >
                            <TextCell>
                              {/* Count past year payment + current payment # + start at 1, not zero */}
                              Payment {index * 12 + paymentIndex + 1} of{" "}
                              {/* Count number of years in a table, and the number of payments in a year */}
                              {totalYears * amortizationRow.values.length} -{" "}
                              <span
                                className="unset-all-style"
                                data-dd-privacy="mask"
                              >
                                {utils.currency.centsToDollars(payment.payment)}
                              </span>
                            </TextCell>
                            <TextCell className="right">
                              <div data-dd-privacy="mask">
                                {utils.currency.centsToDollars(
                                  payment.interestPaidOnPayment
                                )}
                              </div>
                            </TextCell>
                            <TextCell className="right">
                              <div data-dd-privacy="mask">
                                {utils.currency.centsToDollars(
                                  payment.principalPaidOnPayment
                                )}
                              </div>
                            </TextCell>
                            <TextCell className="right">
                              <div data-dd-privacy="mask">
                                {utils.currency.centsToDollars(
                                  payment.principalRemaining
                                )}
                              </div>
                            </TextCell>
                            {/* Empty toggle icon */}
                            <RawCell />
                          </BasicRow>
                        )
                      )}
                    </React.Fragment>
                  );
                }
              )}
              {/* Display the total calculation as a footer */}
              <BasicRow rowClasses={["row-header"]}>
                <TextCell>Total</TextCell>
                <TextCell className="width-20-percent right">
                  <div data-dd-privacy="mask">
                    {utils.currency.centsToDollars(finalInterestAmortized)}
                  </div>
                </TextCell>
                <TextCell className="width-20-percent right">
                  <div data-dd-privacy="mask">
                    {utils.currency.centsToDollars(finalPrincipalAmortized)}
                  </div>
                </TextCell>
                <TextCell className="width-20-percent right">
                  <div data-dd-privacy="mask">
                    {utils.currency.centsToDollars(finalRemainingPrincipal)}
                  </div>
                </TextCell>
              </BasicRow>
            </>
          </Table>
        </>
      ) : null}
    </>
  );
};

export default AmortizingAmortizationTab;
