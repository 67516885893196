import { BasicRow, Table, TextCell, utils } from "@truenorthmortgage/olympus";
import { FC } from "react";
import { useIntl } from "react-intl";
import { LoanQuery } from "../models/loan";
import { toLocalFromUTC } from "../utils";
import { Document } from "../models/loan";

export interface DocumentTabProps {
  productType: string;
  data?: {
    loanData: LoanQuery;
  };
}

const DocumentsTab: FC<DocumentTabProps> = ({ data, productType }) => {
  const intl = useIntl();
  const downloadLabel = intl.formatMessage({
    id: "Download",
  });
  const generalDocumentsLabel = intl.formatMessage({
    id: "General THINK Documents",
  });
  const preDebitAgreementEN = intl.formatMessage({
    id: "Pre-Authorized Debit Agreement (EN)",
  });
  const preDebitAgreementFR = intl.formatMessage({
    id: "Pre-Authorized Debit Agreement (FR)",
  });
  const welcomeEmailLabel = intl.formatMessage({
    id: "Welcome to THINK",
  });
  const latestInfoStatementLabel = intl.formatMessage({
    id: "Latest Information Statement",
  });

  const documentLabel = (documentType: string) => {
    switch (documentType) {
      case "amortizing":
        return intl.formatMessage({ id: "Your Mortgage Documents" });
      case "concierge":
        return intl.formatMessage({ id: "Your Concierge Documents" });
      case "heloc":
        return intl.formatMessage({ id: "Your HELOC Documents" });
    }
  };

  if (!data) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  const formatStatementName = ({
    statementName: statementName,
    generatedForDate: date,
  }: Document) => {
    switch (statementName) {
      case "Welcome Letter":
        return welcomeEmailLabel;
      case "Info Statement":
        return latestInfoStatementLabel;
      case "Annual Statement": {
        // Add timezone info so that JS date conversion stops converting to localtime.
        const dateLocal = new Date(date + "T00:00:00");
        const year = dateLocal.getFullYear();
        return `${year} ${intl.formatMessage({ id: "Annual Statement" })}`;
      }
      default:
        return statementName;
    }
  };

  const formatStatementDate = ({
    statementName: statementName,
    generatedForDate: date,
  }: Document) => {
    switch (statementName) {
      case "Annual Statement": {
        return <></>;
      }
      default:
        return (
          <span data-dd-privacy="mask">
            {utils.date.getShortDate(toLocalFromUTC(date))}
          </span>
        );
    }
  };

  const areMortgageDocumentsAvailable =
    data.loanData?.borrowerLoan.documents?.length > 0;

  return (
    <>
      {areMortgageDocumentsAvailable && (
        <>
          <h2>{documentLabel(productType)}</h2>
          <div className="divider" />
          <Table>
            {data.loanData?.borrowerLoan.documents?.map((document, index) => (
              <BasicRow key={index}>
                <TextCell className="document-item-name">
                  {formatStatementName(document)}
                  {formatStatementDate(document)}
                </TextCell>
                <td className="faux-cell text right">
                  <a href={document.downloadUrl} download>
                    Download
                  </a>
                </td>
              </BasicRow>
            ))}
          </Table>
        </>
      )}

      <h2>{generalDocumentsLabel}</h2>
      <div className="divider" />
      <Table>
        <BasicRow key={"generalthinkdocuments-pre_auth_debit_en"}>
          <TextCell>{preDebitAgreementEN}</TextCell>
          <td className="faux-cell text right">
            <a
              href="https://thinkdocuments.s3.ca-central-1.amazonaws.com/pad-agreement-en.pdf"
              download
            >
              {downloadLabel}
            </a>
          </td>
        </BasicRow>

        <BasicRow key={"generalthinkdocuments-pre_auth_debit_fr"}>
          <TextCell>{preDebitAgreementFR}</TextCell>
          <td className="faux-cell text right">
            <a
              href="https://thinkdocuments.s3.ca-central-1.amazonaws.com/pad-agreement-fr.pdf"
              download
            >
              {downloadLabel}
            </a>
          </td>
        </BasicRow>
      </Table>
    </>
  );
};

export default DocumentsTab;
