import { useIntl } from "react-intl";
import { LoginLayout, PhoneLink } from "../components";

const NeedAccount = () => {
  const intl = useIntl();

  const greeting = intl.formatMessage({ id: "Need an Account?" });

  return (
    <LoginLayout greeting={greeting} className="account-info-card">
      <div>
        <p>
          There are currently two different portals to access your THINK loans,
          depending on the loan type and when your loan was funded. If you have
          a loan supported by this borrower portal, you should have received an
          email with an activation link to create your account and choose a
          password.
        </p>

        <p>
          If your THINK loan is a mortgage, you will need to access the mortgage
          portal found at <a href="https://thinkfinancial.ca/">our website</a>.
        </p>

        <p>
          If you also have a HELOC or other supported loan, and don’t have
          access to the HELOC portal yet, first check your email for activation
          instructions. If you can’t find a message in your inbox, check your
          spam or junk folders.
        </p>

        <p>
          If you can’t find an activation email, contact us at <PhoneLink /> and
          we will help you recover or create your account.
        </p>
      </div>
    </LoginLayout>
  );
};

export default NeedAccount;
