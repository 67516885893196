import {
  utils,
  Table,
  BasicRow,
  RawCell,
  TextCell,
  Widget,
} from "@truenorthmortgage/olympus";
import { FormattedMessage, useIntl } from "react-intl";
import ModalComponent from "../ModalComponent";

const PendingTransactionsTableComponent = ({
  label,
  data,
  paymentRequests,
  cancelRequest,
}: any) => {
  const intl = useIntl();
  return (
    <>
      <h2>{intl.formatMessage({ id: label })}</h2>
      <div className="divider" />
      <Table className="transactions two-column-table x-scrollable">
        {paymentRequests.map((request: any) => (
          <BasicRow key={"allrequest-" + request.id}>
            <TextCell>
              <div data-dd-privacy="mask">
                {intl.formatMessage({ id: request.type })}
                <span>{utils.date.getShortDate(request.effectiveDate)}</span>
              </div>
            </TextCell>
            <TextCell>
              <div data-dd-privacy="mask">
                {utils.currency.centsToDollars(request.data.amount)}
              </div>
            </TextCell>
            <RawCell>
              {" "}
              <button
                className="link-button"
                onClick={() => data.setModal("cancel-" + request.id, true)}
              >
                <i className="fa fa-trash error"></i>
              </button>
            </RawCell>
            <RawCell>
              <ModalComponent
                key={"cancel" + request.id}
                modalHeader={<FormattedMessage id="Cancel Change Request" />}
                isOpen={data.modalOpen["cancel-" + request.id]}
                closeFunc={() => data.setModal("cancel-" + request.id, false)}
                iconClass="fa fa-close"
              >
                <Widget>
                  <div className="column">
                    <FormattedMessage id="Are you sure you want to cancel this change request?" />
                  </div>
                  <div className="column buttons">
                    <div className="buttons">
                      <button
                        className="button cancel"
                        onClick={() =>
                          data.setModal("cancel-" + request.id, false)
                        }
                      >
                        <FormattedMessage id="Cancel" />
                      </button>
                      <button
                        className="button primary right form-trigger"
                        onClick={() => cancelRequest(request.id)}
                      >
                        <FormattedMessage id="Submit" />
                      </button>
                    </div>
                  </div>
                </Widget>
              </ModalComponent>
            </RawCell>
          </BasicRow>
        ))}
      </Table>
    </>
  );
};

export default PendingTransactionsTableComponent;
