import { Widget } from "@truenorthmortgage/olympus";
import { FormattedMessage } from "react-intl";
import { PhoneLink } from "../components";
import { REQUESTS_EMAIL } from "../App";

const ContactUs = () => {
  return (
    <Widget className="contact-us">
      <div className="column">
        <label>
          <FormattedMessage id="Phone" />
        </label>

        <PhoneLink className="primary" />
      </div>
      <div className="column">
        <label>
          <FormattedMessage id="Email" />
        </label>
        <a className="primary" href={`mailto:${REQUESTS_EMAIL}`}>
          {REQUESTS_EMAIL}
        </a>
      </div>
      <div className="column">
        <label>
          <FormattedMessage id="Mailing Address" />
        </label>
        <span className="primary">
          THINK Financial
          <br />
          3600, 205 5th Ave SW
          <br />
          Calgary, AB T2P 2V7
        </span>
      </div>
    </Widget>
  );
};

export default ContactUs;
