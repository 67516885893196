import { useMutation } from "@apollo/client";
import {
  DateComponent,
  Form,
  MoneyComponent,
  Widget,
  utils,
} from "@truenorthmortgage/olympus";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { changeRequestMutation } from "../queries";
import { handleQueryError, notify, valuesToDate } from "../utils";
import dayjs from "dayjs";
import { ChangeRequestMutation } from "../models/payment";

export type MakePaymentToHelocProps = {
  data?: {
    sources: { value: string; label: string }[];
    heloc: any;
  };
  currentBalance: number;
  closeFunc: any;
};

const MakePaymentToHeloc: FC<MakePaymentToHelocProps> = ({
  data,
  closeFunc,
  currentBalance,
}) => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, string | Date | number>>(
    {}
  );
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | Date | number | null | undefined) => void>
  >({});
  const currentDate = useMemo(() => dayjs().startOf("day").toDate(), []);

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | Date | number | null | undefined) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [changeRequest] = useMutation<ChangeRequestMutation>(
    changeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(
          requestError,
          `Failed to submit payment request: ${requestError.message}`
        );
      },
      onCompleted() {
        close();
        notify("Payment request has been submitted");
      },
    }
  );

  const close = () => {
    setState({ amount: "", paymentDate: "" });
    closeFunc();
  };

  const validateParams = useCallback(
    (params: any) => {
      const amount = params.amount;

      if (!amount || amount <= 0) {
        return "Please enter an amount greater than $0.00";
      } else if (amount > currentBalance) {
        return "Amount cannot be greater than current loan balance";
      } else if (!params.paymentDate) {
        return "Please choose a payment date";
      }
      return "";
    },
    [currentBalance]
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    const effectiveDate = utils.date.dbDate(new Date());

    const parsedData = valuesToDate(state, ["paymentDate"]);
    const error = validateParams(parsedData);

    if (!error) {
      await changeRequest({
        variables: {
          loanNumber: data?.heloc.reference,
          type: "CREATEPAYMENT",
          effectiveDate,
          data: parsedData,
        },
      });
    } else {
      notify(error, "error");
    }

    setIsSubmitted(false);
  }, [changeRequest, state, data, validateParams]);

  return data ? (
    <Widget className="make-payment-to-heloc">
      <Form>
        <div className="column">
          <FormattedMessage id="A request may take up to two business days to process" />
        </div>
        <div className="column">
          <MoneyComponent
            id="input-amount"
            label={intl.formatMessage({ id: "Amount" })}
            onChangeCents={onChange("amount")}
            cents={state["amount"] as number}
          />
        </div>
        <DateComponent
          minDate={currentDate}
          label={intl.formatMessage({ id: "Choose Payment Date:" })}
          onChange={onChange("paymentDate")}
          value={state["paymentDate"] as Date}
        />
        <div className="column buttons">
          <div className="buttons">
            <button className="button cancel" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </button>
            <button
              className="button primary right form-trigger"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              <FormattedMessage id="Submit" />
            </button>
          </div>
        </div>
      </Form>
    </Widget>
  ) : null;
};

export default MakePaymentToHeloc;
