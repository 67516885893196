import {
  utils,
  Table,
  BasicRow,
  TextCell,
  Widget,
} from "@truenorthmortgage/olympus";
import { FormattedMessage, useIntl } from "react-intl";
import ModalComponent from "../ModalComponent";

const RecurrentPaymentsTableComponent = ({
  label,
  data,
  cancelRecurringPayment,
}: any) => {
  const intl = useIntl();
  return (
    <>
      <h2>{intl.formatMessage({ id: label })}</h2>
      <div className="divider" />
      <Table className="transactions x-scrollable">
        {data.transactionData!.allBorrowerLoanTransactions.recurringPayments
          .length > 0 ? (
          data.transactionData?.allBorrowerLoanTransactions.recurringPayments.map(
            (payment: any) => (
              <>
                <BasicRow key={"payment-" + payment.id}>
                  <TextCell>
                    <div data-dd-privacy="mask">
                      {intl.formatMessage({ id: payment.paymentFrequency })}{" "}
                      Payment ({payment.paymentsRemaining} payments remaining)
                      <span>
                        {payment.frequencyString}
                        {", "}
                        {new Date(payment.startDate) > new Date()
                          ? "starting " +
                            utils.date.getShortDate(payment.startDate)
                          : "ending " +
                            utils.date.getShortDate(payment.endDate)}
                      </span>
                    </div>
                  </TextCell>
                  <TextCell>
                    <div data-dd-privacy="mask">
                      {utils.currency.centsToDollars(payment.paymentInCents)}
                    </div>
                  </TextCell>
                  <TextCell>
                    {data.requestData?.pendingChangeRequests.find(
                      (request: any) =>
                        request.type == "CANCELRECURRINGPAYMENT" &&
                        request.data.recurringPaymentId == payment.id
                    ) ? (
                      <i className="fa fa-timer" title="Request Pending"></i>
                    ) : (
                      <button
                        className="link-button"
                        onClick={() =>
                          data.setModal("confirmation-" + payment.id, true)
                        }
                      >
                        <i className="fa fa-trash error"></i>
                      </button>
                    )}
                  </TextCell>
                </BasicRow>
                <ModalComponent
                  key={"confirmation-" + payment.id}
                  modalHeader={
                    <FormattedMessage id="Cancel Recurring Payment" />
                  }
                  isOpen={data.modalOpen["confirmation-" + payment.id]}
                  closeFunc={() =>
                    data.setModal("confirmation-" + payment.id, false)
                  }
                  iconClass="fa fa-close"
                >
                  <Widget>
                    <div className="column">
                      <FormattedMessage id="A request may take up to five days to process" />
                    </div>
                    <div className="column buttons">
                      <div className="buttons">
                        <button
                          className="button cancel"
                          onClick={() =>
                            data.setModal("confirmation-" + payment.id, false)
                          }
                        >
                          <FormattedMessage id="Cancel" />
                        </button>
                        <button
                          className="button primary right form-trigger"
                          onClick={() =>
                            cancelRecurringPayment(
                              payment.id,
                              payment.paymentInCents
                            )
                          }
                        >
                          <FormattedMessage id="Submit" />
                        </button>
                      </div>
                    </div>
                  </Widget>
                </ModalComponent>
              </>
            )
          )
        ) : (
          <BasicRow>
            <TextCell colSpan={3}>No Recurring Payments Found</TextCell>
          </BasicRow>
        )}
      </Table>
    </>
  );
};

export default RecurrentPaymentsTableComponent;
