import { useMutation } from "@apollo/client";
import { Form, PasswordComponent, Widget } from "@truenorthmortgage/olympus";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { User } from "../models/user";
import { changePasswordMutation } from "../queries";
import { handleQueryError, notify } from "../utils";

export type ChangePasswordProps = {
  closeFunc: any;
};

export interface ChangePasswordMutation {
  changePassword: User;
}

const ChangePassword: FC<ChangePasswordProps> = ({ closeFunc }) => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, string>>({});
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | Date | null) => void>
  >({});

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | Date | null) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [changePassword] = useMutation(changePasswordMutation, {
    onError(passwordError) {
      handleQueryError(passwordError, passwordError.message);
    },
    onCompleted() {
      close();
      notify("Password has been updated");
    },
  });

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    const error = validateParams(state);

    if (!error) {
      await changePassword({ variables: { ...state } });
    } else {
      notify(error, "error");
    }

    setIsSubmitted(false);
  }, [state, changePassword]);

  const close = () => {
    setState({ oldPassword: "", newPassword: "", confirmPassword: "" });
    closeFunc();
  };

  const validateParams = (params: any) => {
    if (!params.oldPassword) {
      return "Please fill out Exisiting Password";
    } else if (!params.newPassword) {
      return "Please fill out New Password";
    } else if (!params.confirmPassword) {
      return "Please fill out Confirm New Password";
    } else if (params.newPassword !== params.confirmPassword) {
      return "New Password and Confirm New Password fields must match";
    }
    return "";
  };

  return (
    <Widget className="change-password">
      <Form>
        <PasswordComponent
          label={intl.formatMessage({ id: "Confirm Existing Password:" })}
          value={state["oldPassword"]}
          onChange={onChange("oldPassword")}
        />

        <PasswordComponent
          label={intl.formatMessage({ id: "New Password:" })}
          value={state["newPassword"]}
          onChange={onChange("newPassword")}
        />

        <PasswordComponent
          label={intl.formatMessage({ id: "Confirm New Password:" })}
          value={state["confirmPassword"]}
          onChange={onChange("confirmPassword")}
        />
        <div className="column buttons">
          <div className="buttons">
            <button className="button cancel" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </button>
            <button
              className="button primary right form-trigger"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              <FormattedMessage id="Update" />
            </button>
          </div>
        </div>
      </Form>
    </Widget>
  );
};

export default ChangePassword;
