import { FC } from "react";

export interface TagDivProps {
  id: string;
  text: string;
  color: string;
  className?: string;
}

const TagDiv: FC<TagDivProps> = ({ id, text, color, className = "" }) => {
  return (
    <div id={id} className={`tag ${color} ${className}`}>
      {text}
    </div>
  );
};

export default TagDiv;
