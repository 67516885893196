import { Outlet, useLocation } from "react-router-dom";

import BreadcrumbsComponent from "./components/BreadcrumbsComponent";
import NavComponent from "./components/NavComponent";
import headerImage from "./images/illustration.svg";
import headerImageDesktop from "./images/illustration-desktop.svg";
import { Notifications } from "@truenorthmortgage/olympus";
import NotificationComponent from "./components/NotificationComponent";

const Layout = () => {
  const location = useLocation();

  const breadcrumbs = [{ label: "Dashboard", path: "/" }];
  const path_acc = "";
  const paths = location.pathname.split("/");

  const breadcrumbLabel = (path: string, loanNumber: string | null) => {
    return {
      heloc: `HELOC ${loanNumber}`,
      concierge: `Concierge ${loanNumber}`,
      mortgage: `Mortgage ${loanNumber}`,
      account: "My Account",
    }[path];
  };
  paths.forEach((pathString: string, i) => {
    const loanNumber = ["heloc", "mortgage", "concierge"].includes(pathString)
      ? paths[i + 1]
      : null;

    const path =
      path_acc + "/" + pathString + (loanNumber ? `/${loanNumber}` : "");

    const label = breadcrumbLabel(pathString, loanNumber);

    if (label && pathString && i + 1 === paths.length) {
      breadcrumbs.push({ label, path: path + location.search });
    } else if (label && pathString) {
      breadcrumbs.push({ label, path });
    }
  });

  const token = sessionStorage.getItem("thinkToken");

  return (
    <>
      {token &&
        location.pathname !== "/login" &&
        location.pathname !== "/account/create" &&
        location.pathname !== "/account/forgot-password" && <NavComponent />}
      <div id="main">
        <Notifications customNotificationElement={<NotificationComponent />} />
        <div className="main-header">
          {token &&
            location.pathname !== "/login" &&
            location.pathname !== "/account/create" &&
            location.pathname !== "/account/forgot-password" && (
              <BreadcrumbsComponent items={breadcrumbs} />
            )}
          <img
            src={headerImage}
            srcSet={`${headerImage} 300w, ${headerImageDesktop} 768w`}
            alt="header"
          />
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
