import { BasicRow, Table, TextCell, utils } from "@truenorthmortgage/olympus";
import { FC } from "react";
import { LoanQuery } from "../../../models/loan";
import { useIntl } from "react-intl";
import { dateStringToUTCDate } from "../../../utils";

export interface DetailsTabProps {
  data?: {
    loanData: LoanQuery;
  };
}

const DetailsTab: FC<DetailsTabProps> = ({ data }) => {
  const intl = useIntl();

  const balanceLabel = intl.formatMessage({ id: "Loan Balance" });
  const propertyDetailsLabel = intl.formatMessage({ id: "Property Details" });

  if (!data) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return data ? (
    <>
      <h2>{balanceLabel}</h2>
      <div className="divider" />
      <Table className="primary table-first-child-bold">
        <BasicRow>
          <TextCell>Rate</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data.loanData?.borrowerLoan.loanRate.currentRate}%
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>Balance</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                data.loanData?.borrowerLoan.mortgageBalance.principal
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>Maturity Date</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.date.getShortDate(
                dateStringToUTCDate(
                  data.loanData?.borrowerLoan.activePaymentSchedule.endDate
                )
              )}
            </div>
          </TextCell>
        </BasicRow>
      </Table>

      <h2>{propertyDetailsLabel}</h2>
      <div className="divider" />
      <Table className="table-first-child-bold">
        <BasicRow>
          <TextCell>Property Address</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data.loanData?.borrowerLoan.address.lineOne}
              <br />
              {data.loanData?.borrowerLoan.address.city}{" "}
              {data.loanData?.borrowerLoan.address.province},{" "}
              {data.loanData?.borrowerLoan.address.country}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>Borrowers</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data.loanData?.borrowerLoan.borrowers
                .map(
                  (borrower: any) =>
                    `${borrower.firstName} ${borrower.lastName}`
                )
                .join(", ")}
            </div>
          </TextCell>
        </BasicRow>
      </Table>
    </>
  ) : null;
};

export default DetailsTab;
