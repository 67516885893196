import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation loginBorrowerMutation(
    $email: String!
    $password: String!
    $rememberMe: Boolean
  ) {
    loginBorrower(email: $email, password: $password, rememberMe: $rememberMe)
  }
`;

export const logoutMutation = gql`
  mutation {
    logout
  }
`;

export const createBorrowerUserMutation = gql`
  mutation createBorrowerUserMutation($token: String!, $password: String!) {
    createBorrowerUser(token: $token, password: $password)
  }
`;

export const forgotPasswordMutation = gql`
  mutation forgotPasswordMutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPasswordMutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export const changePasswordMutation = gql`
  mutation changePasswordMutation(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      id
      username
      email
      firstName
      lastName
      address {
        lineOne
        lineTwo
        postalCode
        city
        province
        country
      }
    }
  }
`;

export const changeDetailsMutation = gql`
  mutation changeDetailsMutation($newEmail: String, $confirmEmail: String) {
    changeDetails(newEmail: $newEmail, confirmEmail: $confirmEmail) {
      id
      username
      email
      firstName
      lastName
      address {
        lineOne
        lineTwo
        postalCode
        city
        province
        country
      }
    }
  }
`;

export const changeRequestMutation = gql`
  mutation changeRequestMutation(
    $loanNumber: Int!
    $type: ChangeRequestType!
    $data: ChangeRequestDataInput!
    $effectiveDate: Date!
  ) {
    changeRequest(
      loanNumber: $loanNumber
      type: $type
      data: $data
      effectiveDate: $effectiveDate
    ) {
      loanNumber
      type
      effectiveDate
      data {
        amount
        paymentDate
      }
    }
  }
`;

export const cancelChangeRequestMutation = gql`
  mutation cancelChangeRequestMutation($id: Int!) {
    cancelChangeRequest(id: $id) {
      id
      state
    }
  }
`;

export const pendingChangeRequestsQuery = gql`
  query pendingChangeRequestsQuery($loanNumber: Int!) {
    pendingChangeRequests(loanNumber: $loanNumber) {
      id
      loanNumber
      type
      effectiveDate
      state
      data {
        amount
        paymentDate
        recurringPaymentId
      }
    }
  }
`;

export const currentUserQuery = gql`
  query {
    currentUser {
      id
      username
      email
      firstName
      lastName
      address {
        lineOne
        lineTwo
        postalCode
        city
        province
        country
      }
    }
  }
`;

export const loansQuery = gql`
  query ($productCategories: [ProductCategory]!) {
    borrowerLoans(productCategories: $productCategories) {
      status {
        color
        name
      }
      reference
      originalAmortization
      originalPenaltyCoveredInCents
      originalPrincipalInCents
      originalRebateInCents
      originalTerm
      primaryBorrower {
        firstName
        lastName
      }
      borrowers {
        firstName
        lastName
      }
      loanRate {
        currentRate
      }
      address {
        lineOne
        lineTwo
        postalCode
        city
        province
        country
      }
      product {
        termLengthInMonths
        type
        marketingName
        category
      }
    }
  }
`;

export const loanQuery = gql`
  query loanQuery($loanNumber: Int!) {
    borrowerLoan(loanNumber: $loanNumber) {
      id
      activePaymentSchedule {
        interestRate
        endDate
        paymentType
      }
      product {
        type
        termLengthInMonths
      }
      status {
        color
        name
      }
      reference
      originalAmortization
      expectedAmortization
      originalPenaltyCoveredInCents
      originalPrincipalInCents
      originalRebateInCents
      originalTerm
      nextPaymentDate
      primaryBorrower {
        firstName
        lastName
      }
      borrowers {
        firstName
        lastName
      }
      loanRate {
        variance
        currentRate
      }
      address {
        lineOne
        lineTwo
        postalCode
        city
        province
        country
      }
      mortgageBalance {
        principal
        balance
        limit
        available
      }
      interestOwingToday
      parentReference
      monthlyStatements {
        id
        startDate
        endDate
        url
      }
      documents {
        id
        statementName
        downloadUrl
        generatedForDate
      }
    }
  }
`;

export const transactionsQuery = gql`
  query transactionsQuery($loanNumber: Int!) {
    allBorrowerLoanTransactions(loanNumber: $loanNumber) {
      pendingTransactions {
        id
        estimatedInterestAmountInCents
        amountInCents
        effectiveDate
        type
      }
      recurringPayments {
        id
        paymentInCents
        endDate
        startDate
        paymentFrequency
        frequencyString
        paymentsRemaining
      }
      transactions {
        id
        amountInCents
        effectiveDate
        type
        balance
      }
    }
  }
`;

export const loanPrepaymentDetailQuery = gql`
  query ($reference: String!, $effectiveDate: Date!) {
    loanPrepaymentDetail(reference: $reference, effectiveDate: $effectiveDate) {
      totalPrepaymentPrivilegeAmount
      previousPrepayments
      remainingPrivilegeAmount
      anniversaryYearEnd
    }
  }
`;

export const getBorrowerLoanTotalPaymentOnDateQuery = gql`
  query ($reference: String!, $effectiveDate: Date) {
    getBorrowerLoanTotalPaymentOnDate(
      reference: $reference
      effectiveDate: $effectiveDate
    )
  }
`;

export const getAmortizationTableByAnniversaryYearForBorrowerLoanQuery = gql`
  query ($reference: String!) {
    getAmortizationTableByAnniversaryYearForBorrowerLoan(
      reference: $reference
    ) {
      year
      stats {
        principalRemainingAtYearEnd
        totalInterestInAnniversaryYear
        totalPrincipalInAnniversaryYear
        totalPaymentsInAnniversaryYear
      }
      values {
        date
        principalRemaining
        principalPaidOnPayment
        interestPaidOnPayment
        payment
      }
    }
  }
`;

export const emailFromActivationTokenQuery = gql`
  query emailFromActivationTokenQuery($token: String!) {
    emailFromActivationToken(token: $token)
  }
`;

export const emailFromResetTokenQuery = gql`
  query emailFromResetTokenQuery($token: String!) {
    emailFromResetToken(token: $token)
  }
`;
