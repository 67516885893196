import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  getBorrowerLoanTotalPaymentOnDateQuery,
  loanQuery,
  pendingChangeRequestsQuery,
  transactionsQuery,
} from "../queries";
import { useQueryWithRefresh } from "../utils";

import {
  TabGroup,
  TabGroupTab,
  utils,
  TabGroupHandle,
  ActionCards,
  ActionCard,
} from "@truenorthmortgage/olympus";
import { handleQueryError } from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetBorrowerLoanTotalPaymentOnDateQuery,
  LoanQuery,
} from "../models/loan";
import ModalComponent from "../components/ModalComponent";
import AmortizingDetailsTab from "./amortizing/AmortizingDetailsTab";
import ContactUs from "../modal/ContactUs";
import AmortizingActivityTab from "./amortizing/AmortizingActivityTab";
import {
  AllTransactionsQuery,
  PendingPaymentRequestsQuery,
} from "../models/payment";
import DocumentsTab from "./DocumentsTab";
import AmortizingAmortizationTab from "./amortizing/AmortizingAmortizationTab";

const Mortgage = () => {
  const intl = useIntl();
  const nextPaymentAmountLabel = intl.formatMessage({
    id: "Next Payment Amount",
  });
  const onLabel = intl.formatMessage({
    id: "On",
  });
  const optionLabel = intl.formatMessage({ id: "Actions" });
  const prepaymentLabel = intl.formatMessage({ id: "Request Pre-Payment" });
  const psChangeLabel = intl.formatMessage({ id: "Change Payment Schedule" });
  const infoStatementsLabel = intl.formatMessage({ id: "Info Statements" });
  const contactLabel = intl.formatMessage({ id: "Contact THINK Financial" });

  const navigate = useNavigate();
  const { loanNumber, tab } = useParams();

  const modalDefault: any = {
    withdrawal: false,
    payment: false,
    recurring: false,
  };
  const [modalOpen, setModalOpen] =
    useState<Record<string, boolean>>(modalDefault);
  const tabs = ["details", "activity", "amortization", "documents"];
  const ref = useRef<TabGroupHandle>(null);
  const [nextPaymentAmount, setNextPaymentAmount] = useState<number | null>(
    null
  );

  const [
    getLoan,
    { data: loanData, loading: loadingLoan, called: loanCalled },
  ] = useQueryWithRefresh<LoanQuery>(loanQuery, {
    variables: { loanNumber: parseInt(loanNumber!) },
    onError(loanError: any) {
      handleQueryError(loanError, "Failed to load loan");
    },
  });

  useEffect(() => {
    getLoan();
  }, [getLoan]);

  const [calculateNextPaymentAmount, { loading: nextPaymentAmountLoading }] =
    useQueryWithRefresh<GetBorrowerLoanTotalPaymentOnDateQuery>(
      getBorrowerLoanTotalPaymentOnDateQuery,
      {
        fetchPolicy: "network-only",
        variables: {
          reference: loanNumber!,
          effectiveDate: loanData?.borrowerLoan.nextPaymentDate,
        },
        onCompleted(data) {
          setNextPaymentAmount(data.getBorrowerLoanTotalPaymentOnDate);
        },
        onError(loanError: any) {
          handleQueryError(loanError, "Failed to load next payment amount");
        },
      }
    );

  useEffect(() => {
    if (loanNumber && loanData?.borrowerLoan?.nextPaymentDate) {
      calculateNextPaymentAmount();
    }
  }, [loanData?.borrowerLoan.nextPaymentDate, calculateNextPaymentAmount]);

  const [getTransactions] = useQueryWithRefresh<AllTransactionsQuery>(
    transactionsQuery,
    {
      variables: { loanNumber: parseInt(loanNumber || "0") },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load transactions");
      },
    }
  );

  const [getRequestData] = useQueryWithRefresh<PendingPaymentRequestsQuery>(
    pendingChangeRequestsQuery,
    {
      variables: { loanNumber: parseInt(loanNumber || "0") },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load pending requests");
      },
    }
  );

  const setModal = (key: string, value: boolean) => {
    setModalOpen((state: Record<string, boolean>) =>
      Object.assign({}, state, { [key]: value })
    );
    ref.current?.refreshData();
  };

  const cards = useMemo(
    () =>
      loanData
        ? [
            {
              hide: true,
              label: prepaymentLabel,
              icon: "dollar-sign",
              key: "payment",
              modal: {
                // modal header
                header: "",
                elem: null,
              },
            },
            {
              hide: true,
              label: psChangeLabel,
              icon: "calendar-alt",
              key: "payment-schedule-change",
              modal: {
                header: "",
                elem: null,
              },
            },
            {
              hide: true,
              label: infoStatementsLabel,
              icon: "file-invoice",
              key: "info-statement",
              modal: {
                header: "",
                elem: null,
              },
            },
            {
              hide: false,
              label: contactLabel,
              icon: "phone-rotary",
              key: "contact",
              modal: {
                header: "Contact Us",
                elem: <ContactUs />,
              },
            },
          ]
        : null,
    [
      intl,
      prepaymentLabel,
      psChangeLabel,
      infoStatementsLabel,
      contactLabel,
      loanData,
    ]
  );

  return loadingLoan || !loanCalled || nextPaymentAmountLoading ? (
    <div className="loading-full">
      <img src="/think-loader.svg" alt="" />
    </div>
  ) : (
    <div className="mortgage-view">
      <div className="loan-header">
        {nextPaymentAmount && (
          <>
            <h3>{nextPaymentAmountLabel}</h3>
            <h1 data-dd-privacy="mask">
              {nextPaymentAmount
                ? utils.currency.centsToDollars(nextPaymentAmount)
                : "-"}
            </h1>
            <p>
              {onLabel}&nbsp;
              <span className="primary" data-dd-privacy="mask">
                {utils.date.getShortDate(
                  loanData!.borrowerLoan.nextPaymentDate!
                )}
              </span>
            </p>
          </>
        )}
      </div>

      <h2>{optionLabel}</h2>
      <div className="divider" />
      <ActionCards>
        {cards!.map(({ hide, label, icon, key }) => (
          <Fragment key={key}>
            {/* Hide currently unused card buttons */}
            {!hide && (
              <ActionCard key={key} onClick={() => setModal(key, true)}>
                <i className={"fa fa-" + icon}></i>
                <span>{label}</span>
              </ActionCard>
            )}
          </Fragment>
        ))}
      </ActionCards>

      {cards!.map(({ hide, modal, key }) => (
        <Fragment key={key}>
          {/* Hide currently unused card modals */}
          {!hide && (
            <ModalComponent
              modalHeader={modal.header}
              isOpen={modalOpen[key]}
              closeFunc={() => setModal(key, false)}
              iconClass="fa fa-close"
            >
              {modal.elem}
            </ModalComponent>
          )}
        </Fragment>
      ))}

      <TabGroup
        ref={ref}
        selectedTab={tabs.indexOf(tab || "details")}
        onTabChange={(tab) =>
          navigate("/mortgage/" + loanNumber + "/" + tabs[tab])
        }
      >
        <TabGroupTab
          name="Details"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <AmortizingDetailsTab />
        </TabGroupTab>

        <TabGroupTab
          name="Activity"
          loadProps={async () => ({
            data: {
              transactionData: await getTransactions(),
              requestData: await getRequestData(),
              setModal,
            },
          })}
        >
          <AmortizingActivityTab />
        </TabGroupTab>

        <TabGroupTab
          name="Amortization"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <AmortizingAmortizationTab />
        </TabGroupTab>

        <TabGroupTab
          name="Documents"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <DocumentsTab productType="amortizing" />
        </TabGroupTab>
      </TabGroup>
      <div className="footer-content"></div>
    </div>
  );
};

export default Mortgage;
