import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { LoanPrepaymentDetailQuery, LoanQuery } from "../../models/loan";
import { BasicRow, Table, TextCell, utils } from "@truenorthmortgage/olympus";
import { useIntl } from "react-intl";
import {
  capitalize,
  dateStringToUTCDate,
  daysOfWeek,
  nMonthsToYearMonthString,
  paymentFrequencyToHumanReadable,
} from "../../utils";
import { useLazyQuery } from "@apollo/client";
import { loanPrepaymentDetailQuery } from "../../queries";
import TagDiv from "../../components/TagDiv";

export interface AmortizingDetailsTabProps {
  data?: {
    loanData: LoanQuery;
  };
}

const AmortizingDetailsTab: FC<AmortizingDetailsTabProps> = ({ data }) => {
  const intl = useIntl();

  const balanceHeaderLabel = intl.formatMessage({ id: "Mortgage Balance" });
  const detailsHeaderLabel = intl.formatMessage({ id: "Mortgage Details" });
  const propertyHeaderLabel = intl.formatMessage({
    id: "Property Info Details",
  });
  const startingBalanceRowTitle = intl.formatMessage({
    id: "Starting Balance",
  });
  const balanceRowTitle = intl.formatMessage({ id: "Balance" });
  const balancePaidRowTitle = intl.formatMessage({ id: "Balance Paid" });
  const remainingLumpPrepaymentRowTitle = intl.formatMessage({
    id: "Remaining Lump Principal Pre-payment Privileges",
  });
  const principalBalanceRowTitle = intl.formatMessage({
    id: "Principal Balance",
  });
  const loanStatusRowTitle = intl.formatMessage({
    id: "Loan Status",
  });
  const interestRateRowTitle = intl.formatMessage({ id: "Interest Rate" });
  const productRowTitle = intl.formatMessage({ id: "Product" });
  const termRowTitle = intl.formatMessage({ id: "Term" });
  const paymentFrequencyRowTitle = intl.formatMessage({
    id: "Payment Frequency",
  });
  const maturityDateRowTitle = intl.formatMessage({ id: "Maturity Date" });
  const propertyTaxRowTitle = intl.formatMessage({ id: "Property Tax" });
  const remaingAmortizationRowTitle = intl.formatMessage({
    id: "Remaining Amortization",
  });
  const propertyAddressRowTitle = intl.formatMessage({
    id: "Property Address",
  });

  const [
    getLoanPrepaymentDetail,
    { data: prepaymentDetailData, loading: prepaymentDetailLoading },
  ] = useLazyQuery<LoanPrepaymentDetailQuery>(loanPrepaymentDetailQuery, {});

  useEffect(() => {
    if (data?.loanData?.borrowerLoan?.id) {
      getLoanPrepaymentDetail({
        variables: {
          reference: data?.loanData?.borrowerLoan?.reference.toString(),
          effectiveDate: utils.date.dbDate(new Date()),
        },
      });
    }
  }, [data]);

  if (prepaymentDetailLoading) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return data ? (
    <>
      <h2>{balanceHeaderLabel}</h2>
      <div className="divider" />
      <Table className="primary two-column-table table-first-child-bold">
        <BasicRow>
          <TextCell>{startingBalanceRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                data.loanData?.borrowerLoan?.mortgageBalance?.limit
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{balanceRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                data.loanData?.borrowerLoan?.mortgageBalance?.principal
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{balancePaidRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                data.loanData?.borrowerLoan?.mortgageBalance?.limit -
                  data.loanData?.borrowerLoan?.mortgageBalance?.principal
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{remainingLumpPrepaymentRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                Math.max(
                  prepaymentDetailData?.loanPrepaymentDetail
                    ?.remainingPrivilegeAmount || 0,
                  0
                )
              )}
            </div>
          </TextCell>
        </BasicRow>
      </Table>

      <h2>{detailsHeaderLabel}</h2>
      <div className="divider" />
      <Table className="two-column-table table-first-child-bold">
        <BasicRow>
          <TextCell>{loanStatusRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data.loanData?.borrowerLoan.status.name === "Active" ? (
                <div className="loan-status-detail">
                  <div>Active</div>
                </div>
              ) : (
                <div className="loan-status-detail">
                  <TagDiv
                    id="loan-status"
                    color={data.loanData?.borrowerLoan.status.color}
                    text={data.loanData?.borrowerLoan.status.name}
                  />
                  {data.loanData?.borrowerLoan.status.name ===
                    "Pending Payout" && (
                    <div className="payout-doc-available">
                      Payout Statement available in{" "}
                      <Link
                        reloadDocument={true}
                        key={data.loanData?.borrowerLoan.reference}
                        to={`/mortgage/${data.loanData?.borrowerLoan.reference}/documents`}
                      >
                        Documents
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{principalBalanceRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {utils.currency.centsToDollars(
                data.loanData?.borrowerLoan?.mortgageBalance?.principal
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{interestRateRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data.loanData?.borrowerLoan.loanRate.currentRate}%
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{productRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {capitalize(data.loanData?.borrowerLoan.product.type)}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{termRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {nMonthsToYearMonthString(
                data.loanData?.borrowerLoan.product.termLengthInMonths
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{paymentFrequencyRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {paymentFrequencyToHumanReadable(
                data.loanData?.borrowerLoan.activePaymentSchedule.paymentType
              )}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{maturityDateRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {`${
                daysOfWeek[
                  dateStringToUTCDate(
                    data.loanData?.borrowerLoan.activePaymentSchedule.endDate
                  ).getDay()
                ]
              }, ${utils.date.getShortDate(
                dateStringToUTCDate(
                  data.loanData?.borrowerLoan.activePaymentSchedule.endDate
                )
              )}`}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{propertyTaxRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {/* // todo currently not backported  */}
              N/A
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>{remaingAmortizationRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {nMonthsToYearMonthString(
                data.loanData?.borrowerLoan.expectedAmortization
              )}
            </div>
          </TextCell>
        </BasicRow>
      </Table>

      <h2>{propertyHeaderLabel}</h2>
      <div className="divider" />
      <Table className="two-column-table">
        <BasicRow>
          <TextCell>{propertyAddressRowTitle}</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {data.loanData?.borrowerLoan.address.lineOne}
              <br />
              {data.loanData?.borrowerLoan.address.city}{" "}
              {data.loanData?.borrowerLoan.address.province},{" "}
              {data.loanData?.borrowerLoan.address.country}
            </div>
          </TextCell>
        </BasicRow>
      </Table>
    </>
  ) : null;
};

export default AmortizingDetailsTab;
