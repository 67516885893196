import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";

const httpLink = new HttpLink({
  uri:
    window.REACT_APP_ARES_URL + window.REACT_APP_GRAPHQL_ENDPOINT ||
    "http://localhost:4000/api/external/graphql",
});

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = sessionStorage.getItem("thinkToken");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
      fetchOptions: {
        credentials: "include",
      },
    });

    return forward(operation);
  });

export const Client = () => {
  return new ApolloClient({
    link: authMiddleware().concat(httpLink),
    cache: new InMemoryCache(),
    headers: {
      Origin: window.REACT_APP_PORTAL_DOMAIN,
      "Allow-Control-Request-Headers": ["Content-Type", "Accept", "Set-Cookie"],
    },
  });
};
