import { FC, RefObject, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import PendingTransactionsTableComponent from "../../components/tables/PendingTransactionsTableComponent";
import UpcomingTransactionsTableComponent from "../../components/tables/UpcomingTransactionsTableComponent";
import PastTransactionsTableComponent from "../../components/tables/PastTransactionsTableComponent";
import { TabGroupTabHandle } from "@truenorthmortgage/olympus";
import { CancelChangeRequestMutation } from "../../models/loan";
import {
  AllTransactionsQuery,
  PendingPaymentRequestsQuery,
} from "../../models/payment";
import { useMutation } from "@apollo/client";
import { cancelChangeRequestMutation } from "../../queries";
import { handleQueryError, notify } from "../../utils";

export interface AmortizingActivityTabProps {
  data?: {
    setModal: (key: string, value: boolean) => void;
    transactionData?: AllTransactionsQuery;
    requestData: PendingPaymentRequestsQuery;
  };
  parentRef?: RefObject<TabGroupTabHandle>;
}

const AmortizingActivityTab: FC<AmortizingActivityTabProps> = ({
  data,
  parentRef,
}) => {
  const intl = useIntl();

  const pendingTransactionsLabel = intl.formatMessage({
    id: "Pending Requests",
  });
  const upcomingTransactionsLabel = intl.formatMessage({
    id: "Upcoming Transactions",
  });
  const pastTransactionsLabel = intl.formatMessage({ id: "Past Transactions" });
  const headerLabelTransaction = intl.formatMessage({ id: "Transaction" });
  const headerLabelAmount = intl.formatMessage({ id: "Amount" });
  const headerLabelBalance = intl.formatMessage({ id: "End of Day Balance" });

  const paymentRequests = useMemo(
    () =>
      (data?.requestData?.pendingChangeRequests || []).filter((request: any) =>
        ["CREATEWITHDRAWAL", "CREATEPAYMENT"].includes(request.type)
      ),
    [data?.requestData]
  );

  const [cancelChangeRequest] = useMutation<CancelChangeRequestMutation>(
    cancelChangeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(requestError, "Failed to cancel change request");
      },
      onCompleted() {
        parentRef?.current?.refreshData();
        notify("Change request has been cancelled");
      },
    }
  );

  const cancelRequest = useCallback(
    (id: string) => {
      data?.setModal("cancel-" + id, false);
      cancelChangeRequest({
        variables: {
          id: parseInt(id),
        },
      });
    },
    [cancelChangeRequest]
  );

  if (!data) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return (
    <>
      {paymentRequests.length > 0 && (
        <>
          <PendingTransactionsTableComponent
            label={pendingTransactionsLabel}
            paymentRequests={paymentRequests}
            data={data}
            cancelRequest={cancelRequest}
          />
        </>
      )}
      <UpcomingTransactionsTableComponent
        label={upcomingTransactionsLabel}
        data={data}
        loanType={"AMORTIZING"}
      />
      <PastTransactionsTableComponent
        label={pastTransactionsLabel}
        headerLabelAmount={headerLabelAmount}
        headerLabelBalance={headerLabelBalance}
        headerLabelTransaction={headerLabelTransaction}
        data={data}
      />
    </>
  );
};

export default AmortizingActivityTab;
