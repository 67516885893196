import { useMutation } from "@apollo/client";
import { Form, TextComponent, Widget } from "@truenorthmortgage/olympus";
import { FC, useCallback, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserContext } from "../App";
import { User } from "../models/user";
import { changeDetailsMutation } from "../queries";
import { handleQueryError, notify } from "../utils";

export type UpdateAccountDetailsProps = {
  data?: {
    currentEmail: string;
  };
  closeFunc: any;
};

export interface ChangeDetailsMutation {
  changeDetails: User;
}

const UpdateAccountDetails: FC<UpdateAccountDetailsProps> = ({
  data,
  closeFunc,
}) => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, string>>({});
  const { user, setUser } = useContext(UserContext);
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | null) => void>
  >({});

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | null) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [changeDetails] = useMutation<ChangeDetailsMutation>(
    changeDetailsMutation,
    {
      onError(detailsError) {
        handleQueryError(detailsError, detailsError.message);
      },
      onCompleted(data) {
        close();
        notify("Account details have been updated");
        setUser(data.changeDetails);
      },
    }
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    const error = validateParams(state);

    if (!error) {
      await changeDetails({ variables: { ...state } });
    } else {
      notify(error, "error");
    }

    setIsSubmitted(false);
  }, [changeDetails, state]);

  const close = () => {
    setState({ newEmail: "", confirmEmail: "" });
    closeFunc();
  };

  const validateParams = (params: any) => {
    if (!params.newEmail) {
      return "Please fill out New Email";
    } else if (!params.confirmEmail) {
      return "Please fill out Confirm Email";
    } else if (params.newEmail != params.confirmEmail) {
      return "New Email and Confirm New Email fields must match";
    }
    return "";
  };

  return data ? (
    <Widget className="update-account-details">
      <Form>
        <div className="column">
          <label>
            <FormattedMessage id="Current Email Address" />
          </label>
          <p className="current-email padded">{user?.email}</p>
        </div>

        <TextComponent
          label={intl.formatMessage({ id: "New Email:" })}
          formData={state["newEmail"]}
          onChange={onChange("newEmail")}
        />

        <TextComponent
          label={intl.formatMessage({ id: "Confirm Email:" })}
          formData={state["confirmEmail"]}
          onChange={onChange("confirmEmail")}
        />
        <div className="column buttons">
          <div className="buttons">
            <button className="button cancel" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </button>
            <button
              className="button primary right"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              <FormattedMessage id="Update" />
            </button>
          </div>
        </div>
      </Form>
    </Widget>
  ) : null;
};

export default UpdateAccountDetails;
