import { Dispatch } from "@reduxjs/toolkit";
import { FC, HTMLAttributes } from "react";
import { Notification } from "@truenorthmortgage/olympus";

export interface BasicNotificationProps extends HTMLAttributes<HTMLElement> {
  notification?: Notification;
  closeNotification?: (
    notification: Notification,
    dispatch: Dispatch<any>
  ) => void;
}

const NotificationComponent: FC<BasicNotificationProps> = ({
  notification,
  closeNotification,
}) => {
  return notification && closeNotification ? (
    <div className={`notification-inner ${notification.class}`}>
      <div className="notification-background" />
      <i
        className={
          "fa fa-solid fa-" +
          (notification.class === "success" ? "check" : "close")
        }
      ></i>
      <span dangerouslySetInnerHTML={notification.messageHtml} />
    </div>
  ) : null;
};

export default NotificationComponent;
