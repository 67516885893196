import "./App.scss";
import React, { useMemo, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { datadogRum } from "@datadog/browser-rum";
import {
  IntlProvider,
  pushNotification,
  showSearchBar,
  store,
  utils,
} from "@truenorthmortgage/olympus";
import { ApolloProvider } from "@apollo/client";

import { Client } from "./apolloConfig";
import Heloc from "./pages/Heloc";
import Login from "./pages/Login";
import Account from "./pages/Account";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import Concierge from "./pages/Concierge";
import NeedAccount from "./pages/NeedAccount";
import ResetPassword from "./pages/ResetPassword";
import CreateBorrower from "./pages/CreateBorrower";
import { NotificationComponent } from "./components";
import Layout from "./Layout";
import { currentUserQuery } from "./queries";
import messages from "./translations";
import Mortgage from "./pages/Mortgage";

export const PHONE = "1-877-343-0883";
export const REQUESTS_EMAIL = "requests@thinkfinancial.ca";
export const PAD_FORM_PDF_EN_HREF =
  "https://thinkdocuments.s3.ca-central-1.amazonaws.com/THINK PAD Form - (EN).pdf";
export const PAD_FORM_PDF_FR_HREF =
  "https://thinkdocuments.s3.ca-central-1.amazonaws.com/THINK PAD Form - (FR).pdf";

declare global {
  interface Window {
    REACT_APP_ENVIRONMENT: string;
    REACT_APP_ARES_URL: string;
    REACT_APP_GRAPHQL_ENDPOINT: string;
    REACT_APP_PORTAL_DOMAIN: string;
    DATADOG_CLIENT_TOKEN: string;
    DATADOG_SAMPLE_RATE: number;
    DATADOG_REPLAY_RATE: number;
    APP_VERSION: string;
  }
}

datadogRum.init({
  applicationId: "28672a53-1c19-4524-8bee-19b20f942e6b",
  clientToken: window.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "think-borrower-portal",
  env: window.REACT_APP_ENVIRONMENT,
  version: window.APP_VERSION,
  sessionSampleRate: window.DATADOG_SAMPLE_RATE,
  sessionReplaySampleRate: window.DATADOG_REPLAY_RATE,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

utils.font.setFontAwesome();
store.dispatch(showSearchBar(false));

export const UserContext = React.createContext({
  user: {} as any,
  setUser: {} as any,
});

function App() {
  const client = useMemo(() => Client(), []);
  const loginPath = window.location.pathname.match("login");
  const createPath = window.location.pathname.match("/account/create");
  const forgotPath = window.location.pathname.match("/account/forgot-password");

  const [user, setUser] = useState({});

  useMemo(() => {
    return client
      .query({ query: currentUserQuery })
      .then(({ data }) => {
        setUser(data.currentUser || {});

        if (data.currentUser) {
          datadogRum.setUser({
            id: data.currentUser.id,
            name: data.currentUser.firstName + " " + data.currentUser.lastName,
            email: data.currentUser.email,
          });
          datadogRum.startSessionReplayRecording();
        }

        if (!loginPath && !createPath && !forgotPath && !data.currentUser) {
          sessionStorage.removeItem("thinkToken");
        } else if (loginPath && data.currentUser) {
          window.location.replace("/");
        }
      })
      .catch(() => {
        if (!loginPath && !createPath && !forgotPath) {
          sessionStorage.removeItem("thinkToken");
        }
      });
  }, [client, forgotPath, loginPath, createPath]);

  const PrivateRoute = () => {
    if (sessionStorage.getItem("thinkToken")) {
      return <Outlet />;
    } else {
      store.dispatch(
        pushNotification({
          message: "Please log in",
          class: "error",
          notificationElement: NotificationComponent,
        })
      );
      return <Navigate to="/login" replace />;
    }
  };

  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <IntlProvider messages={messages}>
          <UserContext.Provider value={{ user, setUser }}>
            <CookiesProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route path="login" element={<Login />} />
                    <Route
                      path="/account/need-account"
                      element={<NeedAccount />}
                    />
                    <Route
                      path="/account/create"
                      element={<CreateBorrower />}
                    />
                    <Route
                      path="/account/forgot-password"
                      element={<ResetPassword />}
                    />

                    <Route path="/" element={<PrivateRoute />}>
                      <Route path="/" element={<Dashboard />} />
                    </Route>
                    <Route path="/account" element={<PrivateRoute />}>
                      <Route path="/account" element={<Account />} />
                    </Route>
                    <Route
                      path="/mortgage/:loanNumber"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/mortgage/:loanNumber"
                        element={<Mortgage />}
                      />
                      <Route
                        path="/mortgage/:loanNumber/:tab"
                        element={<PrivateRoute />}
                      >
                        <Route
                          path="/mortgage/:loanNumber/:tab"
                          element={<Mortgage />}
                        />
                      </Route>
                    </Route>
                    <Route path="/heloc/:loanNumber" element={<PrivateRoute />}>
                      <Route path="/heloc/:loanNumber" element={<Heloc />} />
                    </Route>
                    <Route
                      path="/heloc/:loanNumber/:tab"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/heloc/:loanNumber/:tab"
                        element={<Heloc />}
                      />
                    </Route>
                    <Route
                      path="/concierge/:loanNumber"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/concierge/:loanNumber"
                        element={<Concierge />}
                      />
                    </Route>
                    <Route
                      path="/concierge/:loanNumber/:tab"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/concierge/:loanNumber/:tab"
                        element={<Concierge />}
                      />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </CookiesProvider>
          </UserContext.Provider>
        </IntlProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default App;
