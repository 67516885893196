import { useIntl } from "react-intl";
import { PhoneLink } from "../components";

const Login = () => {
  const intl = useIntl();

  const header = intl.formatMessage({ id: "Not Found" });
  const body = intl.formatMessage({
    id: "Sorry, we could not find the view you are looking for.",
  });
  const homeLink = intl.formatMessage({ id: "Back to Home" });

  return (
    <div className="has-footer">
      <div className="not-found-card">
        <h1>{header}</h1>
        <p>
          {body}
          <br />
        </p>
        <p>
          <a href="/">{homeLink}</a>
        </p>
      </div>
      <div className="footer">
        <div>
          Need help?{" "}
          <strong>
            <PhoneLink />
          </strong>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Login;
