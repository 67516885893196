import { MouseEvent, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  TextComponent,
  CheckboxOption,
  PasswordComponent,
  CheckboxComponent,
} from "@truenorthmortgage/olympus";
import { loginMutation } from "../queries";
import { handleQueryError } from "../utils";
import { LoginMutation } from "../models/user";
import { LoginLayout } from "../components";

const Login = () => {
  const intl = useIntl();

  const greeting = intl.formatMessage({ id: "Please Log In" });
  const emailPlaceholder = intl.formatMessage({ id: "Email" });
  const passwordPlaceholder = intl.formatMessage({ id: "Password" });
  const rememberMeMsg = intl.formatMessage({ id: "Remember Me" });
  const buttonlabel = intl.formatMessage({ id: "Login" });
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState({
    email: cookies.user || "",
    password: "",
    rememberMe: !!cookies.user,
  });
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | boolean | null) => void>
  >({});

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | boolean | null) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [loginUser, status] = useMutation<LoginMutation>(loginMutation, {
    onError(loginError) {
      handleQueryError(
        loginError,
        "Account login details not found, please try again"
      );
    },
    onCompleted(data) {
      sessionStorage.setItem("thinkToken", data.loginBorrower);
      window.location.replace("/");
    },
  });

  const onSubmit = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      setIsSubmitted(true);

      e.preventDefault();
      try {
        const variables = {
          email: state.email,
          password: state.password,
        };
        if (state.rememberMe) {
          setCookie("user", state.email, { maxAge: 60 * 60 * 24 * 7 });
        } else {
          removeCookie("user");
        }

        await loginUser({ variables });
      } catch (e) {
        handleQueryError(
          e,
          "Account login details not found, please try again"
        );
      }

      setIsSubmitted(false);
    },
    [loginUser, state, setCookie, removeCookie]
  );

  return (
    <LoginLayout greeting={greeting} className="login-card">
      <form>
        <TextComponent
          formData={state["email"]}
          onChange={onChange("email")}
          placeholder={emailPlaceholder}
        />

        <PasswordComponent
          value={state["password"]}
          onChange={onChange("password")}
          placeholder={passwordPlaceholder}
        />

        <CheckboxComponent
          name="rememberMe"
          value={state["rememberMe"]}
          onChange={onChange("rememberMe")}
        >
          <CheckboxOption label={rememberMeMsg} value="true" />
        </CheckboxComponent>

        <button
          className="button primary"
          disabled={isSubmitted}
          onClick={onSubmit}
        >
          {status.loading ? (
            <i className="fa fa-spinner fa-spin" />
          ) : (
            buttonlabel
          )}
        </button>
      </form>

      <div className="login-card__link">
        <Link to="/account/need-account">Need an account?</Link>
      </div>
    </LoginLayout>
  );
};

export default Login;
