import { utils, Table, BasicRow, TextCell } from "@truenorthmortgage/olympus";
import { useIntl } from "react-intl";
import { capitalize } from "../../utils";

const UpcomingTransactionsTableComponent = ({ label, data, loanType }: any) => {
  const intl = useIntl();

  return (
    <>
      <h2>{intl.formatMessage({ id: label })}</h2>
      <div className="divider" />
      <Table className="transactions two-column-table x-scrollable">
        {data.transactionData!.allBorrowerLoanTransactions.pendingTransactions
          .length > 0 ? (
          data.transactionData?.allBorrowerLoanTransactions.pendingTransactions.map(
            (transaction: any) => (
              <BasicRow key={"alltransaction-" + transaction.id}>
                {/*
                 * Some pending transactions will have an estimated amount. The actual amount isn't calculated until
                 * the day of processing. Currently this is used for upcoming interest payments, but could also
                 * be used in the future for amortizing schedules that show estimated interest and principal payments.
                 */}
                {transaction.amountInCents === null ? (
                  <>
                    <TextCell>
                      <div data-dd-privacy="mask">
                        {utils.date.getShortDate(transaction.effectiveDate)}
                        <span>
                          {loanType === "AMORTIZING"
                            ? intl.formatMessage({
                                id: "Principal + Interest Payment",
                              })
                            : `Estimated ${capitalize(
                                intl.formatMessage({ id: transaction.type })
                              )}`}
                        </span>
                      </div>
                    </TextCell>
                    <TextCell className="right">
                      <div data-dd-privacy="mask">
                        {utils.currency.centsToDollars(
                          transaction.estimatedInterestAmountInCents
                        )}
                      </div>
                    </TextCell>
                  </>
                ) : (
                  <>
                    <TextCell>
                      <div data-dd-privacy="mask">
                        {utils.date.getShortDate(transaction.effectiveDate)}
                        <span>
                          {transaction.type === "principal" &&
                          loanType === "AMORTIZING"
                            ? intl.formatMessage({
                                id: "Principal Privilege Payment",
                              })
                            : capitalize(
                                intl.formatMessage({ id: transaction.type })
                              )}
                        </span>
                      </div>
                    </TextCell>
                    <TextCell className="right">
                      <div data-dd-privacy="mask">
                        {utils.currency.centsToDollars(
                          transaction.amountInCents
                        )}
                      </div>
                    </TextCell>
                  </>
                )}
              </BasicRow>
            )
          )
        ) : (
          <BasicRow>
            <TextCell colSpan={2}>No Upcoming Transactions Found</TextCell>
          </BasicRow>
        )}
      </Table>
    </>
  );
};

export default UpcomingTransactionsTableComponent;
