import { useMutation } from "@apollo/client";
import {
  DateComponent,
  Form,
  MoneyComponent,
  SelectComponent,
  Widget,
  utils,
} from "@truenorthmortgage/olympus";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { changeRequestMutation } from "../queries";
import { handleQueryError, notify, valuesToDate } from "../utils";
import dayjs from "dayjs";
import { ChangeRequestMutation } from "../models/payment";

export type RecurringPaymentProps = {
  data?: {
    accounts: { value: string; label: string }[];
    heloc: any;
  };
  closeFunc: any;
};
const RecurringPayment: FC<RecurringPaymentProps> = ({ data, closeFunc }) => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, string | number | Date>>(
    {}
  );
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | number | Date | null | undefined) => void>
  >({});
  const currentDate = useMemo(() => dayjs().startOf("day").toDate(), []);

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | number | Date | null | undefined) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [changeRequest] = useMutation<ChangeRequestMutation>(
    changeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(
          requestError,
          `Failed to submit payment request: ${requestError.message}`
        );
      },
      onCompleted(_data) {
        close();
        notify("Payment request has been submitted");
      },
    }
  );

  const validateParams = useCallback((params: any) => {
    const amount = params.amount;
    const paymentFrequency = params.paymentFrequency;

    if (!amount || amount <= 0) {
      return "Please enter an amount greater than $0.00";
    } else if (!params.startDate) {
      return "Please choose a start date";
    } else if (!params.endDate) {
      return "Please choose a end date";
    } else if (
      !paymentFrequency ||
      paymentFrequency === "-- Select a Frequency --"
    ) {
      return "Please choose a payment frequency";
    }
    return "";
  }, []);

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    const effectiveDate = utils.date.dbDate(new Date());

    const parsedData = valuesToDate(state, ["startDate", "endDate"]);
    const error = validateParams(parsedData);

    if (!error) {
      await changeRequest({
        variables: {
          loanNumber: data?.heloc.reference,
          type: "CREATERECURRINGPAYMENT",
          effectiveDate,
          data: parsedData,
        },
      });
    } else {
      notify(error, "error");
    }

    setIsSubmitted(false);
  }, [changeRequest, state, data, validateParams]);

  const close = () => {
    setState({ amount: "", startDate: "", endDate: "", paymentFrequency: "" });
    closeFunc();
  };

  return data ? (
    <Widget className="recurring-payment">
      <Form>
        <div className="column">
          <FormattedMessage id="A request may take up to five days to process" />
        </div>
        <div className="column">
          <MoneyComponent
            id="input-amount"
            label={intl.formatMessage({ id: "Payment Amount" })}
            onChangeCents={onChange("amount")}
            cents={state["amount"] as number}
          />
        </div>
        <DateComponent
          minDate={currentDate}
          label={intl.formatMessage({ id: "Start Date" })}
          onChange={onChange("startDate")}
          value={state["startDate"] as Date}
        />
        <DateComponent
          minDate={state["startDate"] as Date}
          label={intl.formatMessage({ id: "End Date" })}
          onChange={onChange("endDate")}
          value={state["endDate"] as Date}
        />
        <SelectComponent
          onChange={onChange("paymentFrequency")}
          value={state["paymentFrequency"] as string}
          label={intl.formatMessage({ id: "Frequency" })}
          defaultValue={"-- Select a Frequency --"}
        >
          {[
            "-- Select a Frequency --",
            "MONTHLY",
            "SEMI_MONTHLY",
            "WEEKLY",
            "BIWEEKLY",
          ].map((frequency) => (
            <option key={frequency} value={frequency}>
              {intl.formatMessage({ id: frequency })}
            </option>
          ))}
        </SelectComponent>

        <div className="column buttons">
          <div className="buttons">
            <button className="button cancel" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </button>
            <button
              className="button primary right form-trigger"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              <FormattedMessage id="Submit" />
            </button>
          </div>
        </div>
      </Form>
    </Widget>
  ) : null;
};

export default RecurringPayment;
