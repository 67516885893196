import { useState, useMemo } from "react";

const ModalComponent = ({
  children,
  isOpen,
  closeFunc,
  iconClass,
  modalHeader,
}: any) => {
  const [isOpenState, setIsOpen] = useState(!!isOpen);

  useMemo(() => {
    setIsOpen(isOpen);
    const root = document.getElementById("root");
    if (root) root.className = isOpen ? "modal-open" : "";
  }, [isOpen]);

  return (
    <div
      className={`modal-container ${isOpenState ? "open" : ""}`}
      tabIndex={0}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modal-mask" onClick={() => closeFunc(false)} />
      <div className="modal-box">
        {modalHeader ? (
          <div className="modal-header">
            <div className="modal-title-box">
              <b className="modal-title">{modalHeader}</b>
            </div>
            <div className="modal-close" onClick={() => closeFunc(false)}>
              {iconClass && <i className={iconClass}></i>}
            </div>
          </div>
        ) : (
          <div className="modal-close" onClick={() => closeFunc(false)}>
            {iconClass && <i className={iconClass}></i>}
          </div>
        )}

        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default ModalComponent;
