import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import logo from "../../images/think-logo.svg";
import { PhoneLink } from "../phoneLink";

interface LayoutInterface {
  greeting: string;
  className: string;
  children: ReactNode;
}

const displayVersion = "THINK Borrower Portal " + window.APP_VERSION;

const LoginLayout: FC<LayoutInterface> = ({
  className = "",
  greeting,
  children,
}) => {
  return (
    <div className="has-footer">
      <div className={className}>
        <div className="img-container">
          <a href="/" title={displayVersion}>
            <img src={logo} className="app-logo" alt="logo" />
          </a>
        </div>
        <h1>{greeting}</h1>

        {children}
      </div>

      <div className="footer">
        <div className="footer__phone-link">
          Need help?
          <PhoneLink />
        </div>

        <div></div>

        <Link to="/account/forgot-password">
          <FormattedMessage id="Forgot Password?" />
        </Link>
      </div>
    </div>
  );
};

export default LoginLayout;
