import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import logo from "../images/think-logo.svg";

import {
  store,
  pushNotification,
  PasswordComponent,
  Form,
  TextComponent,
} from "@truenorthmortgage/olympus";

import {
  forgotPasswordMutation,
  resetPasswordMutation,
  emailFromResetTokenQuery,
  loginMutation,
} from "../queries";
import { useIntl } from "react-intl";
import { handleQueryError } from "../utils";
import { LoginMutation } from "../models/user";
import { PhoneLink } from "../components";

export interface EmailFromResetTokenQuery {
  emailFromResetToken: string;
}

const ResetPassword = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const intl = useIntl();
  const continueLabel = intl.formatMessage({ id: "Continue" });
  const buttonlabel = intl.formatMessage({ id: "Send Reset Email" });
  const resetButtonlabel = intl.formatMessage({ id: "Reset Password" });
  const emailPlaceholder = intl.formatMessage({ id: "Email" });

  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    success: false,
    emailSuccess: false,
  });

  const { data: emailData, loading: loadingEmail } =
    useQuery<EmailFromResetTokenQuery>(emailFromResetTokenQuery, {
      variables: { token },
    });

  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | null) => void>
  >({});

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | Date | null) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [resetPassword] = useMutation(resetPasswordMutation, {
    onError(resetError) {
      handleQueryError(resetError, resetError.message);
    },
    onCompleted() {
      setState((state) => Object.assign({}, state, { success: true }));
    },
  });

  const [forgotPassword, status] = useMutation(forgotPasswordMutation, {
    onError(createError) {
      handleQueryError(createError, "Failed to send reset email.");
    },
    onCompleted() {
      setState((state) => Object.assign({}, state, { emailSuccess: true }));
    },
  });

  const submitReset = useCallback(() => {
    if (state.password && state.password === state.confirmPassword) {
      resetPassword({
        variables: { password: state.password, token },
      });
    } else {
      store.dispatch(
        pushNotification({
          message: "Passwords must match!",
          class: "error",
        })
      );
    }
  }, [resetPassword, state, token]);

  const [loginUserFunc, loginStatus] = useMutation<LoginMutation>(
    loginMutation,
    {
      onError(loginError) {
        handleQueryError(
          loginError,
          "Account login details not found, please try again"
        );
        window.location.replace("/login");
      },
      onCompleted(data) {
        sessionStorage.setItem("thinkToken", data.loginBorrower);
        window.location.replace("/");
      },
    }
  );

  const loginUser = useCallback(() => {
    try {
      const variables = {
        email: emailData!.emailFromResetToken,
        password: state.password,
      };

      loginUserFunc({ variables });
    } catch (e) {
      handleQueryError(e, "Account login details not found, please try again");
      window.location.replace("/login");
    }
  }, [loginUserFunc, state, emailData]);

  const submitRequest = useCallback(() => {
    forgotPassword({
      variables: { email: state.email },
    });
  }, [forgotPassword, state]);

  const greeting = useMemo(
    () =>
      state.success
        ? "Password has been reset"
        : state.emailSuccess
        ? "Check your email"
        : token
        ? "Set New Password"
        : "Forgot your Password?",
    [state, token]
  );

  return loadingEmail ? (
    <div className="loading-full">
      <img src="/think-loader.svg" alt="loader" />
    </div>
  ) : (
    <div className="has-footer">
      <div className="setup-card">
        <div className="img-container">
          <a href="/">
            <img src={logo} className="app-logo" alt="logo" />
          </a>
        </div>
        <h1>{intl.formatMessage({ id: greeting })}</h1>
        {state.success ? (
          <div>
            <div>
              Your password has been successfully reset. Click below to login
              automatically
            </div>
            <div>
              <button className="button primary" onClick={loginUser}>
                {loginStatus.loading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  continueLabel
                )}
              </button>
            </div>
          </div>
        ) : state.emailSuccess ? (
          <div>
            <p>
              We sent a reset password link to <br />
              <b>{state.email}</b>
            </p>

            <p>Password reset links are only valid for 15 minutes.</p>
            <p>
              Didn’t receive the email?{" "}
              <button className="link-button" onClick={submitRequest}>
                Click to resend
              </button>
            </p>
          </div>
        ) : token ? (
          <>
            <Form>
              <input
                className="disabled"
                disabled={true}
                value={emailData?.emailFromResetToken}
              />

              <PasswordComponent
                placeholder={intl.formatMessage({ id: "Password" })}
                value={state.password}
                onChange={onChange("password")}
              />

              <PasswordComponent
                placeholder={intl.formatMessage({ id: "Confirm Password" })}
                value={state.confirmPassword}
                onChange={onChange("confirmPassword")}
              />

              <button className="button primary" onClick={submitReset}>
                {status.loading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  resetButtonlabel
                )}
              </button>
            </Form>
          </>
        ) : (
          <>
            <p className="mt-3">
              It happens to the best of us! Please enter the address associated
              with your account.
            </p>
            <Form>
              <TextComponent
                formData={state.email}
                onChange={onChange("email")}
                placeholder={emailPlaceholder}
              />
              <button className="button primary" onClick={submitRequest}>
                {status.loading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  buttonlabel
                )}
              </button>
            </Form>
          </>
        )}
      </div>
      <div className="footer">
        <div>
          Need help?{" "}
          <strong>
            <PhoneLink />
          </strong>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
