import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  loanQuery,
  transactionsQuery,
  pendingChangeRequestsQuery,
} from "../queries";
import { useQueryWithRefresh } from "../utils";

import {
  TabGroup,
  TabGroupTab,
  utils,
  TabGroupHandle,
  ActionCards,
  ActionCard,
} from "@truenorthmortgage/olympus";
import { handleQueryError } from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import { LoanQuery } from "../models/loan";
import {
  AllTransactionsQuery,
  PendingPaymentRequestsQuery,
} from "../models/payment";
import DetailsTab from "./concierge/tabs/DetailsTab";
import ActivityTab from "./concierge/tabs/ActivityTab";
import DocumentsTab from "./DocumentsTab";
import MakePaymentToConcierge from "../modal/MakePaymentToConcierge";
import ModalComponent from "../components/ModalComponent";

const Concierge = () => {
  const intl = useIntl();

  const loanBalanceLabel = intl.formatMessage({ id: "Loan Balance" });
  const nextPaymentLabel = intl.formatMessage({ id: "Next Payment Date" });
  const optionLabel = intl.formatMessage({ id: "Actions" });
  const paymentLabel = intl.formatMessage({ id: "Make Payment" });
  const navigate = useNavigate();

  const { loanNumber, tab } = useParams();
  const tabs = ["details", "activity", "documents"];
  const modalDefault: any = {
    withdrawal: false,
    payment: false,
    recurring: false,
  };

  const [modalOpen, setModalOpen] =
    useState<Record<string, boolean>>(modalDefault);
  const ref = useRef<TabGroupHandle>(null);

  const [
    getLoan,
    { data: loanData, loading: loadingLoan, called: loanCalled },
  ] = useQueryWithRefresh<LoanQuery>(loanQuery, {
    variables: { loanNumber: parseInt(loanNumber || "0") },
    onError(loanError: any) {
      handleQueryError(loanError, "Failed to load loan");
    },
  });

  useEffect(() => {
    getLoan();
  }, [getLoan]);

  const [getTransactions] = useQueryWithRefresh<AllTransactionsQuery>(
    transactionsQuery,
    {
      variables: { loanNumber: parseInt(loanNumber || "0") },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load transactions");
      },
    }
  );

  const [getRequestData] = useQueryWithRefresh<PendingPaymentRequestsQuery>(
    pendingChangeRequestsQuery,
    {
      variables: { loanNumber: parseInt(loanNumber || "0") },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load pending requests");
      },
    }
  );

  const setModal = (key: string, value: boolean) => {
    setModalOpen((state: Record<string, boolean>) =>
      Object.assign({}, state, { [key]: value })
    );
    ref.current?.refreshData();
  };

  const cards = useMemo(
    () =>
      loanData
        ? [
            {
              hide: true,
              label: paymentLabel,
              icon: "circle-dollar-to-slot",
              key: "payment",
              modal: {
                header: intl.formatMessage({ id: "Make Payment" }),
                elem: (
                  <MakePaymentToConcierge
                    data={{
                      sources: [
                        { value: "123456789123", label: "RBC - 123456789123" },
                      ],
                      concierge: loanData?.borrowerLoan,
                    }}
                    closeFunc={() => setModal("payment", false)}
                  />
                ),
              },
            },
          ]
        : null,
    [intl, paymentLabel, loanData]
  );

  return loadingLoan || !loanCalled ? (
    <div className="loading-full">
      <img src="/think-loader.svg" alt="" />
    </div>
  ) : (
    <div className="heloc">
      <div className="loan-header">
        <h3>{loanBalanceLabel}</h3>
        <h1 data-dd-privacy="mask">
          {utils.currency.centsToDollars(
            loanData?.borrowerLoan.mortgageBalance.principal
          )}
        </h1>
        <p>
          {nextPaymentLabel}&nbsp;
          <span className="primary" data-dd-privacy="mask">
            {utils.date.getShortDate(loanData!.borrowerLoan.nextPaymentDate!)}
          </span>
        </p>
      </div>

      {/* Render the actions menu only if at least one action is not hidden */}
      {cards!.some(({ hide }) => !hide) && (
        <>
          <h2>{optionLabel}</h2>
          <div className="divider" />

          <ActionCards>
            {cards!.map(({ hide, label, icon, key }) => (
              <Fragment key={key}>
                {/* Hide currently unused card buttons */}
                {!hide && (
                  <ActionCard key={key} onClick={() => setModal(key, true)}>
                    <i className={"fa fa-" + icon}></i>
                    <span>{label}</span>
                  </ActionCard>
                )}
              </Fragment>
            ))}
          </ActionCards>

          {cards!.map(({ hide, modal, key }) => (
            <Fragment key={key}>
              {/* Hide currently unused card modals */}
              {!hide && (
                <ModalComponent
                  key={key}
                  modalHeader={modal.header}
                  isOpen={modalOpen[key]}
                  closeFunc={() => setModal(key, false)}
                  iconClass="fa fa-close"
                >
                  {modal.elem}
                </ModalComponent>
              )}
            </Fragment>
          ))}
        </>
      )}

      <TabGroup
        ref={ref}
        selectedTab={tabs.indexOf(tab || "details")}
        onTabChange={(tab) =>
          navigate("/concierge/" + loanNumber + "/" + tabs[tab])
        }
      >
        <TabGroupTab
          name="Details"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <DetailsTab />
        </TabGroupTab>
        <TabGroupTab
          name="Activity"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
              requestData: await getRequestData(),
              transactionData: await getTransactions(),
              setModal,
              modalOpen,
            },
          })}
        >
          <ActivityTab />
        </TabGroupTab>
        <TabGroupTab
          name="Documents"
          loadProps={async () => ({
            data: {
              loanData: await getLoan(),
            },
          })}
        >
          <DocumentsTab productType="concierge" />
        </TabGroupTab>
      </TabGroup>
      <div className="footer-content"></div>
    </div>
  );
};

export default Concierge;
