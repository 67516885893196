import { handleQueryError, notify } from "../../../utils";
import { TabGroupTabHandle, utils } from "@truenorthmortgage/olympus";
import { FC, RefObject, useCallback, useMemo } from "react";
import {
  AllTransactionsQuery,
  ChangeRequestMutation,
  PendingPaymentRequestsQuery,
} from "../../../models/payment";
import { useMutation } from "@apollo/client";
import {
  cancelChangeRequestMutation,
  changeRequestMutation,
} from "../../../queries";
import { CancelChangeRequestMutation, LoanQuery } from "../../../models/loan";
import PendingTransactionsTableComponent from "../../../components/tables/PendingTransactionsTableComponent";
import UpcomingTransactionsTableComponent from "../../../components/tables/UpcomingTransactionsTableComponent";
import RecurrentPaymentsTableComponent from "../../../components/tables/RecurrentPaymentsTableComponent";
import PastTransactionsTableComponent from "../../../components/tables/PastTransactionsTableComponent";

export interface ActivityTabProps {
  data?: {
    modalOpen: Record<string, boolean>;
    setModal: (key: string, value: boolean) => void;
    loanData: LoanQuery;
    requestData: PendingPaymentRequestsQuery;
    transactionData: AllTransactionsQuery;
  };
  parentRef?: RefObject<TabGroupTabHandle>;
}

const ActivityTab: FC<ActivityTabProps> = ({ data, parentRef }) => {
  const pendingTransactionsLabel = "Pending Requests";
  const upcomingTransactionsLabel = "Upcoming Transactions";
  const pastTransactionsLabel = "Past Transactions";
  const headerLabelTransaction = "Transaction";
  const headerLabelAmount = "Amount";
  const headerLabelBalance = "End of Day Balance";
  const recurringPaymentsLabel = "Recurring Payments";

  const [changeRequest] = useMutation<ChangeRequestMutation>(
    changeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(requestError, "Failed to submit payment request");
      },
      onCompleted() {
        parentRef?.current?.refreshData();
        notify("Cancellation request has been submitted");
      },
    }
  );

  const [cancelChangeRequest] = useMutation<CancelChangeRequestMutation>(
    cancelChangeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(requestError, "Failed to cancel change request");
      },
      onCompleted() {
        parentRef?.current?.refreshData();
        notify("Change request has been cancelled");
      },
    }
  );

  const cancelRecurringPayment = useCallback(
    (id: string, amount: number) => {
      const effectiveDate = utils.date.dbDate(new Date());
      data?.setModal("confirmation-" + id, false);
      changeRequest({
        variables: {
          effectiveDate,
          loanNumber: data?.loanData?.borrowerLoan.reference,
          data: { recurringPaymentId: id, amount: amount },
          type: "CANCELRECURRINGPAYMENT",
        },
      });
    },
    [changeRequest, data?.loanData]
  );

  const cancelRequest = useCallback(
    (id: string) => {
      data?.setModal("cancel-" + id, false);
      cancelChangeRequest({
        variables: {
          id: parseInt(id),
        },
      });
    },
    [cancelChangeRequest]
  );

  const paymentRequests = useMemo(
    () =>
      (data?.requestData?.pendingChangeRequests || []).filter((request: any) =>
        [
          "CREATEWITHDRAWAL",
          "CREATEPAYMENT",
          "CREATERECURRINGPAYMENT",
          "CANCELRECURRINGPAYMENT",
        ].includes(request.type)
      ),
    [data?.requestData]
  );

  if (!data) {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return (
    <>
      {paymentRequests.length > 0 && (
        <>
          <PendingTransactionsTableComponent
            label={pendingTransactionsLabel}
            paymentRequests={paymentRequests}
            data={data}
            cancelRequest={cancelRequest}
          />
        </>
      )}
      <UpcomingTransactionsTableComponent
        label={upcomingTransactionsLabel}
        data={data}
      />
      <RecurrentPaymentsTableComponent
        label={recurringPaymentsLabel}
        data={data}
        cancelRecurringPayment={cancelRecurringPayment}
      />
      <PastTransactionsTableComponent
        label={pastTransactionsLabel}
        headerLabelAmount={headerLabelAmount}
        headerLabelBalance={headerLabelBalance}
        headerLabelTransaction={headerLabelTransaction}
        data={data}
      />
    </>
  );
};

export default ActivityTab;
