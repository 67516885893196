import { useMutation } from "@apollo/client";
import {
  Form,
  MoneyComponent,
  Widget,
  utils,
} from "@truenorthmortgage/olympus";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ChangeRequestMutation } from "../models/payment";
import { changeRequestMutation } from "../queries";
import { handleQueryError, notify } from "../utils";

export type WithdrawFromHelocProps = {
  data?: {
    heloc: any;
  };
  fundsAvailable: number;
  closeFunc: any;
};

const WithdrawFromHeloc: FC<WithdrawFromHelocProps> = ({
  data,
  closeFunc,
  fundsAvailable,
}) => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, string | number>>({});

  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | number | null | undefined) => void>
  >({});

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | number | null | undefined) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  const [changeRequest] = useMutation<ChangeRequestMutation>(
    changeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(
          requestError,
          `Failed to submit withdrawal request: ${requestError.message}`
        );
      },
      onCompleted() {
        close();
        notify("Withdrawal request has been submitted");
      },
    }
  );

  const validateParams = useCallback(
    (params: any) => {
      const amount = params.amount;

      if (!amount || amount <= 0) {
        return "Please enter an amount greater than $0.00";
      } else if (amount > fundsAvailable) {
        return "Amount cannot be greater than total funds available";
      }
      return "";
    },
    [fundsAvailable]
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    const effectiveDate = utils.date.dbDate(new Date());

    const error = validateParams(state);

    if (!error) {
      await changeRequest({
        variables: {
          loanNumber: data?.heloc.reference,
          type: "CREATEWITHDRAWAL",
          effectiveDate,
          data: state,
        },
      });
    } else {
      notify(error, "error");
    }

    setIsSubmitted(false);
  }, [changeRequest, state, data, validateParams]);

  const close = () => {
    setState({ amount: "" });
    closeFunc();
  };

  return data ? (
    <Widget className="withdraw-from-heloc">
      <Form>
        <div className="column">
          <FormattedMessage id="A request may take up to two business days to process" />
        </div>
        <div className="column">
          <MoneyComponent
            id="input-amount"
            label={intl.formatMessage({ id: "Amount" })}
            onChangeCents={onChange("amount")}
            cents={state["amount"] as number}
          />
        </div>

        <div className="column buttons">
          <div className="buttons">
            <button className="button cancel" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </button>
            <button
              className="button primary right form-trigger"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              <FormattedMessage id="Submit" />
            </button>
          </div>
        </div>
      </Form>
    </Widget>
  ) : null;
};

export default WithdrawFromHeloc;
